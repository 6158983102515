'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('award');

export const state = () => ({
    ...crud.state,
    items: []
});

export const getters = {
    items: state => state.items
};

export const mutations = {
    SET_ITEMS(state, items) {
        state.items = items;
    },

    ADD_ITEM(state, item) {
        state.items.unshift(item);
    }
};

export const actions = {
    ...crud.actions,

    async index({ commit }) {
        const { items } = await this.$axios.$get('/awards', {
            params: {
                sortBy: ['createdAt:DESC']
            }
        });

        commit('SET_ITEMS', items);
    },

    addItem({ commit }, item) {
        commit('ADD_ITEM', item);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
