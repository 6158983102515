import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2157aedd = () => interopDefault(import('../../pages/applicants/index.vue' /* webpackChunkName: "pages/applicants/index" */))
const _13fe7d72 = () => interopDefault(import('../../pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _23a79002 = () => interopDefault(import('../../pages/jobs/index.vue' /* webpackChunkName: "pages/jobs/index" */))
const _c2d90260 = () => interopDefault(import('../../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _79dbf5de = () => interopDefault(import('../../pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _f590659c = () => interopDefault(import('../../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _635322c8 = () => interopDefault(import('../../pages/applicants/hunter/index.vue' /* webpackChunkName: "pages/applicants/hunter/index" */))
const _4183996b = () => interopDefault(import('../../pages/applicants/new.vue' /* webpackChunkName: "pages/applicants/new" */))
const _a1d0a7b0 = () => interopDefault(import('../../pages/applicants/statuses/index.vue' /* webpackChunkName: "pages/applicants/statuses/index" */))
const _d171ed80 = () => interopDefault(import('../../pages/clients/new.vue' /* webpackChunkName: "pages/clients/new" */))
const _30337fd0 = () => interopDefault(import('../../pages/jobs/new.vue' /* webpackChunkName: "pages/jobs/new" */))
const _1a85ec9f = () => interopDefault(import('../../pages/suppliers/new.vue' /* webpackChunkName: "pages/suppliers/new" */))
const _0061613d = () => interopDefault(import('../../pages/users/award.vue' /* webpackChunkName: "pages/users/award" */))
const _dbdd6e00 = () => interopDefault(import('../../pages/users/new.vue' /* webpackChunkName: "pages/users/new" */))
const _5628488e = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1d5b3730 = () => interopDefault(import('../../pages/applicants/hunter/_id.vue' /* webpackChunkName: "pages/applicants/hunter/_id" */))
const _9657d970 = () => interopDefault(import('../../pages/applicants/_id/index.vue' /* webpackChunkName: "pages/applicants/_id/index" */))
const _43978f5d = () => interopDefault(import('../../pages/clients/_id/index.vue' /* webpackChunkName: "pages/clients/_id/index" */))
const _5c54d9ed = () => interopDefault(import('../../pages/jobs/_id/index.vue' /* webpackChunkName: "pages/jobs/_id/index" */))
const _af5fa108 = () => interopDefault(import('../../pages/suppliers/_id/index.vue' /* webpackChunkName: "pages/suppliers/_id/index" */))
const _57ba01c6 = () => interopDefault(import('../../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/applicants",
    component: _2157aedd,
    name: "applicants"
  }, {
    path: "/clients",
    component: _13fe7d72,
    name: "clients"
  }, {
    path: "/jobs",
    component: _23a79002,
    name: "jobs"
  }, {
    path: "/login",
    component: _c2d90260,
    name: "login"
  }, {
    path: "/suppliers",
    component: _79dbf5de,
    name: "suppliers"
  }, {
    path: "/users",
    component: _f590659c,
    name: "users"
  }, {
    path: "/applicants/hunter",
    component: _635322c8,
    name: "applicants-hunter"
  }, {
    path: "/applicants/new",
    component: _4183996b,
    name: "applicants-new"
  }, {
    path: "/applicants/statuses",
    component: _a1d0a7b0,
    name: "applicants-statuses"
  }, {
    path: "/clients/new",
    component: _d171ed80,
    name: "clients-new"
  }, {
    path: "/jobs/new",
    component: _30337fd0,
    name: "jobs-new"
  }, {
    path: "/suppliers/new",
    component: _1a85ec9f,
    name: "suppliers-new"
  }, {
    path: "/users/award",
    component: _0061613d,
    name: "users-award"
  }, {
    path: "/users/new",
    component: _dbdd6e00,
    name: "users-new"
  }, {
    path: "/",
    component: _5628488e,
    name: "index"
  }, {
    path: "/applicants/hunter/:id",
    component: _1d5b3730,
    name: "applicants-hunter-id"
  }, {
    path: "/applicants/:id",
    component: _9657d970,
    name: "applicants-id"
  }, {
    path: "/clients/:id",
    component: _43978f5d,
    name: "clients-id"
  }, {
    path: "/jobs/:id",
    component: _5c54d9ed,
    name: "jobs-id"
  }, {
    path: "/suppliers/:id",
    component: _af5fa108,
    name: "suppliers-id"
  }, {
    path: "/users/:id",
    component: _57ba01c6,
    name: "users-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
