export const state = () => ({
    isLtr: false,
    isRtl: false
});

export const getters = {
    isLtr: state => state.isLtr,
    isRtl: state => state.isRtl
};

export const mutations = {
    SET_IS_LTR(state, isLtr) {
        state.isLtr = isLtr;
    },

    SET_IS_RTL(state, isRtl) {
        state.isRtl = isRtl;
    }
};

export const actions = {
    async nuxtServerInit({ dispatch, getters }, { $cookies, req }) {
        if ($cookies.get('isLoggedIn')) {
            try {
                const link = req.url;

                await dispatch('auth/login');
                await dispatch('tabs/restoreTabs');

                const currentTab = getters['tabs/currentTab'];

                if (currentTab?.link !== link) {
                    await dispatch('tabs/openTab', {
                        link,
                        label: ''
                    });
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            await dispatch('auth/logout');
        }
    },

    setIsLtr({ commit }, isLtr) {
        commit('SET_IS_LTR', isLtr);
    },

    setIsRtl({ commit }, isRtl) {
        commit('SET_IS_RTL', isRtl);
    }
};
