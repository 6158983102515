'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(context, filters) {
        const { recruiterId, clientId, supplierId, statusId, dateRange } =
            filters;
        const [dateFrom, dateTo] = dateRange;
        const params = {
            dateFrom,
            dateTo,
            recruiterId: recruiterId.map(item => item.id),
            clientId: clientId.map(item => item.id),
            supplierId: supplierId.map(item => item.id),
            statusId: statusId.map(item => item.id)
        };

        return this.$axios.$get('/dashboard', { params });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
