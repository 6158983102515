'use strict';

import { v4 as uuid } from 'uuid';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';

const removeLargeItems = obj => {
    obj = cloneDeep(obj);

    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        if (obj.length > 5) {
            return [];
        }
    }

    for (const [key, value] of Object.entries(obj)) {
        const sanitizedValue = removeLargeItems(value);

        obj[key] = sanitizedValue;
    }

    return obj;
};

export const defaultState = () => ({
    items: [],
    oftenUsed: [],
    currentTab: null,
    previousTabId: null,
    restoring: false
});

export const state = () => defaultState();

export const getters = {
    items: state => state.items,
    restoring: state => state.restoring,
    oftenUsed: state => state.oftenUsed,
    currentTab: state => state.currentTab,
    previousTabId: state => state.previousTabId,
    getTabById: state => tabId => state.items.find(item => item.id === tabId)
};

export const mutations = {
    ADD_TAB(state, tab) {
        tab.fetch = true;

        const insertMethod = this.$i18n.locale === 'he' ? 'unshift' : 'push';

        state.items[insertMethod](tab);
    },

    OPEN_TAB(state, tab) {
        console.log('OPEN_TAB', tab);
        const insertMethod = this.$i18n.locale === 'he' ? 'unshift' : 'push';

        state.items[insertMethod](tab);

        state.previousTabId = state.currentTab?.id || tab.id;
        state.currentTab = tab;
    },

    REMOVE_TAB(state, tab) {
        state.items = state.items.filter(item => item.id !== tab.id);

        if (state.currentTab?.id === tab.id) {
            const previousTab = state.items.find(
                item => item.id === state.previousTabId
            );

            if (previousTab) {
                state.currentTab = previousTab;

                return;
            }

            if (state.items.length) {
                state.currentTab = [...state.items].pop();
            } else {
                state.currentTab = null;
                state.previousTabId = null;
            }
        }
    },

    CLEAR_TABS(state) {
        state.items = [];
        state.currentTab = null;
        state.previousTabId = null;
    },

    UPDATE_TAB(state, tab) {
        console.log('UPDATE_TAB', tab);
        const tabIndex = state.items.findIndex(item => item.id === tab.id);

        if (~tabIndex) {
            state.items.splice(tabIndex, 1, cloneDeep(tab));
        }
    },

    SET_ITEMS(state, items) {
        console.log('>>> SET_ITEMS', items);

        state.items = items;
    },

    SET_RESTORING(state, restoring) {
        state.restoring = restoring;
    },

    SET_CURRENT_TAB(state, tabId) {
        console.log('>>> SET_CURRENT_TAB', tabId);

        if (state.previousTabId !== state.currentTab?.id) {
            state.previousTabId = state.currentTab?.id || tabId;
        }

        const tab = state.items.find(item => item.id === tabId);

        if (tab) {
            console.log('>>> SET_CURRENT_TAB >>> setting tab', tab);
            state.currentTab = tab;
        } else {
            console.log(
                `>>> SET_CURRENT_TAB >>> no tab with id: ${tabId} found`
            );
        }
    },

    SET_OFTEN_USED(state, oftenUsed) {
        state.oftenUsed = oftenUsed;
    }
};

export const actions = {
    async addTab({ commit, dispatch }, tab) {
        tab.id = uuid();
        tab.data = tab.data || {};

        commit('ADD_TAB', tab);

        if (tab.addCallback) {
            await dispatch(tab.addCallback, tab.addCallbackData, {
                root: true
            });
        }

        return tab.id;
    },

    async openTab({ commit, dispatch }, tab) {
        tab.id = uuid();
        tab.data = tab.data || {};

        commit('OPEN_TAB', tab);
        commit('SET_CURRENT_TAB', tab.id);

        if (tab.openCallback) {
            await dispatch(tab.openCallback, tab.openCallbackData, {
                root: true
            });
        }

        return tab.id;
    },

    async removeTab({ commit, dispatch }, tab) {
        if (tab.removeCallback) {
            await dispatch(tab.removeCallback, tab.removeCallbackData, {
                root: true
            });
        }

        commit('REMOVE_TAB', tab);
    },

    updateTab({ commit, dispatch }, updatedTab) {
        commit('UPDATE_TAB', updatedTab);

        dispatch('saveTabs');
    },

    setCurrentTab({ commit }, tabId) {
        commit('SET_CURRENT_TAB', tabId);
    },

    refresh() {
        // no action required
    },

    setOftenUsed({ commit }, oftenUsed) {
        commit('SET_OFTEN_USED', oftenUsed);
    },

    saveTabs: debounce(function ({ dispatch }) {
        dispatch('saveTabsNow');
    }, 750),

    saveTabsNow({ getters }) {
        const { items, currentTab } = getters;

        const sanitizedItems = cloneDeep(items).map(item => ({
            ...item,
            data: removeLargeItems(item.data)
        }));

        return this.$axios.$post('/tabs', {
            items: sanitizedItems,
            currentTabId: currentTab.id
        });
    },

    setRestoring({ commit }, restoring) {
        commit('SET_RESTORING', restoring);
    },

    async restoreTabs({ commit, dispatch }) {
        const { items = [], currentTabId } = await this.$axios.$get('/tabs');

        if (items.length) {
            commit('SET_ITEMS', items);
            commit('SET_CURRENT_TAB', currentTabId);
            commit('SET_RESTORING', true);
        }

        for (const tab of items) {
            if (tab.addCallback) {
                await dispatch(tab.addCallback, tab.addCallbackData, {
                    root: true
                });
            }
        }
    },

    clearTabs({ getters, dispatch }) {
        for (const tab of getters.items) {
            dispatch('removeTab', tab);
        }
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
