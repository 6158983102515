<template>
    <div>
        <v-select
            v-model="value"
            class="enum-filter"
            multiple
            :options="options"
            @input="onFilterChange"
        />
        <feather-icon v-if="value.length" type="trash-2" @click="clearValue" />
    </div>
</template>

<script>
export default {
    props: {
        default: {
            type: Object,
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            value: []
        };
    },

    created() {
        if (this.default) {
            const selectedValues = this.default.value;
            const isSimpleArray = this.options.every(value =>
                ['string', 'number'].includes(typeof value)
            );

            if (isSimpleArray) {
                this.value = selectedValues;

                return;
            }

            const selectedOptions = selectedValues.map(value =>
                this.options.find(option => option.value === value)
            );

            this.value = selectedOptions;
        }
    },

    methods: {
        onFilterChange() {
            let value = null;

            if (this.value.length) {
                value = this.value.map(item => item?.value || item);
            }

            const filter = {
                value,
                type: 'in'
            };

            this.$emit('filter-change', filter);
        },

        clearValue() {
            this.value = [];
            this.onFilterChange();
        }
    }
};
</script>
