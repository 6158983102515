<template>
    <div class="card system-tabs">
        <div class="card-header">
            <div class="heading-elements">
                <ul class="tabs-wrapper">
                    <page-tab v-for="tab in tabs" :key="tab.id" :tab="tab" />
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PageTab from '@/components/PageTab';

export default {
    components: {
        PageTab
    },

    computed: {
        ...mapGetters({
            tabs: 'tabs/items',
            currentTab: 'tabs/currentTab'
        })
    }
};
</script>
