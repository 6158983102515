export default ({ $axios, $dayjs, $cookies, store: { dispatch } }) => {
    $axios.onRequest(config => {
        config.headers.timezone = $cookies.get('timezone') || $dayjs.tz.guess();

        return config;
    });

    $axios.onError(error => {
        const code = parseInt(error?.response?.status);

        if (code === 401) {
            return dispatch('auth/logout');
        }
    });
};
