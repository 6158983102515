<template>
    <li
        v-if="$user.role.name === 'admin'"
        v-click-outside="hide"
        class="nav-item d-none d-xl-block"
        @click="show = true"
    >
        <a
            class="nav-link dropdown-toggle d-flex align-items-center"
            title="Settings"
        >
            <feather-icon type="settings" class="ficon" />
        </a>
        <ul class="dropdown-menu" :class="{ show }">
            <li>
                <a
                    class="dropdown-item d-flex align-items-center"
                    @click="showUsersMenu = true"
                >
                    <span>
                        <feather-icon type="chevron-down" />
                        {{ $t('common.header.users') }}
                    </span>
                </a>
                <ul class="dropdown-menu" :class="{ show: showUsersMenu }">
                    <li>
                        <a
                            class="dropdown-item d-flex align-items-center"
                            @click="
                                $openTab({
                                    label: $t('tabs.users.list'),
                                    link: '/users'
                                })
                            "
                        >
                            <span>{{ $t('common.header.list') }}</span>
                        </a>
                    </li>
                    <li>
                        <a
                            class="dropdown-item d-flex align-items-center"
                            @click="
                                $openTab({
                                    label: $t('tabs.users.new'),
                                    link: '/users/new'
                                })
                            "
                        >
                            <span>{{ $t('common.header.new') }}</span>
                        </a>
                    </li>
                    <li>
                        <a
                            class="dropdown-item d-flex align-items-center"
                            @click="
                                $openTab({
                                    label: $t('tabs.users.award'),
                                    link: '/users/award'
                                })
                            "
                        >
                            <span>
                                {{ $t('common.header.manageNotifications') }}
                            </span>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a
                    class="dropdown-item d-flex align-items-center"
                    @click="
                        $openTab({
                            label: $t('tabs.applicants.statuses'),
                            link: '/applicants/statuses'
                        })
                    "
                >
                    <span> {{ $t('common.header.statusManagement') }} </span>
                </a>
            </li>
        </ul>
    </li>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            showUsersMenu: false
        };
    },

    methods: {
        hide() {
            this.show = false;
        }
    }
};
</script>
