var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"awesome-table",class:{ card: _vm.tableOptions.isCard },attrs:{"id":_vm.id}},[_c('div',{staticClass:"card-body",style:({
            'margin-bottom': _vm.isLoading && !_vm.items.length ? '20px' : '0'
        })},[(_vm.tableOptions.header)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-filters-wrapper"},[_vm._t("before-icons"),_vm._v(" "),_c('div',{staticClass:"icons"},[(_vm.tableOptions.columnsSelector)?_c('columns-selector',{attrs:{"labels":_vm.tableLabels},on:{"toggle-display":_vm.onToggleDisplay}}):_vm._e(),_vm._v(" "),(_vm.tableOptions.link)?_c('copy-link',{attrs:{"filters":_vm.tableOptions.filters ? _vm.filters : null,"hidden-labels":_vm.hiddenLabels,"pagination":_vm.tableOptions.pagination
                                    ? _vm.tablePagination
                                    : null,"additional-params":_vm.tableOptions.additionalParams}}):_vm._e(),_vm._v(" "),(_vm.tableOptions.filters)?_c('applied-filters',{attrs:{"filters":_vm.appliedFilters}}):_vm._e(),_vm._v(" "),_vm._t("icons")],2),_vm._v(" "),_vm._t("after-icons")],2),_vm._v(" "),(_vm.tableOptions.search)?_c('div',{staticClass:"input-group input-group-merge header-search"},[_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("\n                            "+_vm._s(_vm.$t('common.search'))+"\n                        ")])]),_vm._v(" "),_c('input',{staticClass:"form-control",attrs:{"dir":"auto","placeholder":_vm.$t('common.search'),"type":"text"},domProps:{"value":_vm.tablePagination.search},on:{"input":_vm.onSearchInput}}),_vm._v(" "),_c('span',{staticClass:"input-group-text"},[_c('feather-icon',{attrs:{"type":"search"}})],1)]):_vm._e()])]):_vm._e(),_vm._v(" "),(
                _vm.tableOptions.select &&
                _vm.tableOptions.showSelectedActions &&
                _vm.selected.length
            )?_c('div',{staticClass:"alert alert-info d-flex justify-content-between align-items-center selected-items-actions",attrs:{"role":"alert"}},[_c('div',[_c('strong',[_vm._v("\n                    "+_vm._s(_vm.$t('awesomeTable.selected'))+": "+_vm._s(_vm.selected.length)+"\n                ")])]),_vm._v(" "),_c('div',[_vm._t("selectButtons"),_vm._v(" "),(_vm.tableOptions.csvExport)?_c('csv-export',{attrs:{"items":_vm.items,"selected":_vm.selected,"labels":_vm.tableLabels,"table-options":_vm.tableOptions}}):_vm._e(),_vm._v(" "),_c('button',{staticClass:"btn btn-info waves-effect waves-light mr-1",attrs:{"type":"button"},on:{"click":function($event){_vm.selected = []}}},[_c('feather-icon',{attrs:{"type":"minus-circle"}}),_vm._v("\n                    "+_vm._s(_vm.$t('awesomeTable.clearSelection'))+"\n                ")],1),_vm._v(" "),(_vm.tableOptions.copyField)?_c('button',{staticClass:"btn btn-info waves-effect waves-light mr-1",attrs:{"type":"button"},on:{"click":_vm.copySelectedRowsToClipboard}},[_c('feather-icon',{attrs:{"type":"copy"}}),_vm._v("\n                    "+_vm._s(_vm.$t('awesomeTable.copySelectedRows'))+"\n                ")],1):_vm._e()],2)]):_vm._e(),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"table-responsive",class:{ 'fixed-header': _vm.tableOptions.fixedHeader }},[_c('table',{staticClass:"table table-centered table-striped text-center",class:_vm.tableOptions.additionalClasses},[_c('thead',[_c('tr',[(_vm.showDrag)?_c('th'):_vm._e(),_vm._v(" "),_vm._l((_vm.displayableLabels),function(label,index){return _c('th',{key:index + '_head',class:{
                                'sortable-label':
                                    _vm.tableOptions.sort &&
                                    label.sortable !== false,
                                'fixed-column': label.fixed
                            },style:(_vm.labelStyle(label, index)),on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.onLabelClick(label)},function($event){if(!$event.altKey)return null;if($event.ctrlKey||$event.shiftKey||$event.metaKey)return null;return _vm.onLabelClick(label, true)}]}},[(_vm.isSorted(label))?_c('div',{staticClass:"sortable-label-wrapper"},[(_vm.isSorted(label))?_c('feather-icon',{attrs:{"type":_vm.getSortingOrder(label) === 'DESC'
                                            ? 'arrow-down'
                                            : 'arrow-up'}}):_vm._e(),_vm._v(" "),_vm._t(`labels.${label.value || label}`,function(){return [_vm._v("\n                                    "+_vm._s(_vm.getLabel(label))+"\n                                ")]},{"label":_vm.getLabel(label)})],2):_vm._t(`labels.${label.value || label}`,function(){return [_vm._v("\n                                "+_vm._s(_vm.getLabel(label))+"\n                            ")]},{"label":_vm.getLabel(label)})],2)}),_vm._v(" "),(_vm.tableOptions.select && _vm.items.length)?_c('th',[(_vm.hasSelectedAnyElement)?_c('div',{staticClass:"some-selected",on:{"click":_vm.toggleAllOnPageSelected}},[_c('span',[_vm._v(" - ")])]):_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.isAnyItemOnPageSelected},on:{"click":_vm.toggleAllOnPageSelected}})]):_vm._e()],2),_vm._v(" "),(_vm.tableOptions.filters)?_c('filters',{attrs:{"labels":_vm.displayableLabels,"init-filters":_vm.filters,"empty-cells-before":_vm.emptyCellsBeforeFiltersCount},on:{"filter-change":_vm.onFilterChange}}):_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('tr',{staticClass:"awesome-table-loader-wrapper"},[_vm._m(1)]):_vm._e()],1),_vm._v(" "),(_vm.items.length)?_c('draggable',{key:_vm.dragKey,style:({ opacity: _vm.isLoading ? '0.3' : '1' }),attrs:{"tag":"tbody","list":_vm.items,"group":"people","handle":".handle"},on:{"start":function($event){_vm.drag = true},"end":_vm.onDragEnd}},[_vm._l((_vm.items),function(item,trIndex){return [_c('tr',{key:trIndex + '_body',class:{
                                [_vm.getRowClass(item, trIndex)]: true,
                                'clickable-row': _vm.tableOptions.clickableRows,
                                active: _vm.activeRows[trIndex]
                            },on:{"click":function($event){return _vm.onRowClick(item, trIndex)}}},[(_vm.showDrag)?_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('button',{staticClass:"handle btn btn-secondary waves-effect waves-light mr-2",staticStyle:{"cursor":"move"},attrs:{"type":"button"}},[_c('feather-icon',{attrs:{"type":"move"}})],1)]):_vm._e(),_vm._v(" "),_vm._l((_vm.displayableLabels),function(label,tdIndex){return _c('td',{key:trIndex + '_body' + tdIndex,ref:`td_${trIndex}_${tdIndex}`,refInFor:true,class:{
                                    'fixed-column': label.fixed
                                },style:(_vm.getCellStyle(label, tdIndex))},[_vm._t(`items.${label.value || label}`,function(){return [(
                                            _vm.isLabelBadgeOfBooleanType(label)
                                        )?_c('span',[(_vm.isItemEmpty(item, label))?_c('span',{class:_vm.getBadgeClass(
                                                    label.badgeOptions,
                                                    'falseVariant'
                                                )},[_vm._v("\n                                            "+_vm._s(_vm.$t('common.no'))+"\n                                        ")]):_c('span',{class:_vm.getBadgeClass(
                                                    label.badgeOptions,
                                                    'trueVariant'
                                                )},[_vm._v("\n                                            "+_vm._s(_vm.$t('common.yes'))+"\n                                        ")])]):_c('span',[_vm._v("\n                                        "+_vm._s(_vm.getValue(item, label))+"\n                                    ")])]},{"value":_vm.getValue(item, label),"item":{ ...item },"index":trIndex})],2)}),_vm._v(" "),(_vm.tableOptions.select)?_c('td',{staticStyle:{"cursor":"default"},on:{"click":function($event){$event.stopPropagation();}}},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.isSelected(item)},on:{"click":function($event){return _vm.toggleSelected(item)}}})]):_vm._e()],2),_vm._v(" "),_vm._t("items.dropdown",null,{"item":item})]})],2):_vm._e(),_vm._v(" "),_c('sum-section',{attrs:{"items":_vm.items,"labels":_vm.displayableLabels,"get-value":_vm.getValue,"empty-cells-before":_vm.emptyCellsBeforeFiltersCount}}),_vm._v(" "),(!_vm.isLoading && !_vm.items.length)?_c('tr',[_c('td',{attrs:{"colspan":_vm.displayableLabels.length}},[_c('b-alert',{staticClass:"text-center",attrs:{"show":""}},[_c('b-row',_vm._l((_vm.tableAlertGridCols),function(index){return _c('b-col',{key:index},[_vm._v("\n                                    "+_vm._s(_vm.tableOptions.noDataText)+"\n                                ")])}),1)],1)],1)]):_vm._e()],1)]),_vm._v(" "),(_vm.tableOptions.pagination && _vm.tablePagination.total)?_c('pagination',{attrs:{"current-page":_vm.tablePagination.currentPage,"total":_vm.tablePagination.total,"per-page":_vm.tablePagination.perPage},on:{"set-page":_vm.setPage,"set-per-page":_vm.onPerPageChange}}):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll-bar"},[_c('div',{staticClass:"inner"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',{staticClass:"awesome-table-loader"},[_c('div',{staticClass:"awesome-spinner"},[_c('div',{staticClass:"dot1"}),_vm._v(" "),_c('div',{staticClass:"dot2"})])])])
}]

export { render, staticRenderFns }