'use strict';

export const defaultState = () => ({});

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index() {
        return this.$axios.$get('/roles');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
