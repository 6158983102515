'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('user');

export const state = () => ({
    ...crud.state
});

export const getters = {};

export const mutations = {};

export const actions = {
    ...crud.actions,

    me() {
        return this.$axios.$get('/me');
    },

    async readNotifications({ dispatch }) {
        const user = await this.$axios.$patch('/users/notifications');

        await dispatch('auth/setUser', user, { root: true });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
