import Vue from 'vue';
import DatePicker from 'vue-flatpickr-component';
import { Hebrew } from 'flatpickr/dist/l10n/he.js';
import { english } from 'flatpickr/dist/l10n/default.js';

Vue.component('date-picker', DatePicker);

export default ({ app: { i18n }, store: { dispatch } }, inject) => {
    const setDirection = locale => {
        const isLtr = locale === 'en';

        dispatch('setIsLtr', isLtr);
        dispatch('setIsRtl', !isLtr);
    };

    setDirection(i18n.locale);

    i18n.onBeforeLanguageSwitch = (_, newLocale) => setDirection(newLocale);

    inject('getDatePickerLocale', () => {
        const locale = i18n.locale === 'he' ? Hebrew : english;

        locale.rangeSeparator = ' - ';

        return locale;
    });
};
