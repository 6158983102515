<template>
    <li
        v-if="showIcon"
        class="nav-item dropdown dropdown-notification me-25"
        @click="show = !show"
    >
        <a class="nav-link" :class="{ show }" data-bs-toggle="dropdown">
            <feather-icon type="bell" class="ficon" />
            <span class="badge rounded-pill badge-up">
                {{ unreadNotifications.length }}
            </span>
        </a>
        <ul
            class="dropdown-menu dropdown-menu-media dropdown-menu-end"
            :class="{ show }"
        >
            <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex">
                    <h4 class="notification-title mb-0 me-auto">
                        {{ $t('notifications.title') }}
                    </h4>
                    <div class="badge rounded-pill">
                        {{ unreadNotifications.length }}
                        {{ $t('notifications.new') }}
                    </div>
                </div>
            </li>
            <li class="scrollable-container media-list">
                <a
                    v-for="notification in unreadNotifications"
                    :key="notification.id"
                    class="d-flex"
                >
                    <div class="list-item d-flex align-items-start">
                        <div class="me-1">
                            <div class="avatar">
                                <img
                                    :src="notification.user.avatar"
                                    alt="avatar"
                                    height="32"
                                    width="32"
                                />
                            </div>
                        </div>
                        <div class="list-item-body flex-grow-1">
                            <p class="media-heading">
                                <span class="fw-bolder">
                                    {{ $t('notifications.congratulations') }}
                                    {{ notification.user.name }}
                                </span>
                            </p>
                            <small class="notification-text">
                                {{ $t('notifications.content') }}
                            </small>
                        </div>
                    </div>
                </a>
            </li>
            <li class="dropdown-menu-footer">
                <a class="btn w-100" @click="readNotifications">
                    {{ $t('notifications.readAll') }}
                </a>
            </li>
        </ul>
    </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            show: false
        };
    },

    async fetch() {
        await this.getAwards();
    },

    computed: {
        ...mapGetters({
            notifications: 'awards/items'
        }),

        showIcon() {
            return this.unreadNotifications.length > 0;
        },

        unreadNotifications() {
            const notificationsReadAt = this.$dayjs(
                this.$user.notificationsReadAt || new Date(2000, 1, 1)
            );

            return this.notifications.filter(({ createdAt }) =>
                this.$dayjs(createdAt).isAfter(notificationsReadAt)
            );
        }
    },

    methods: {
        ...mapActions({
            getAwards: 'awards/index',
            readNotifications: 'users/readNotifications'
        })
    }
};
</script>
