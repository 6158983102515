'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('applicant', 'applicants');

export const state = () => ({
    ...crud.state,
    openApplicants: [],
    activeApplicants: []
});

export const getters = {
    openApplicants: state => state.openApplicants,
    activeApplicants: state => state.activeApplicants,
    isApplicantOpen: state => applicantId =>
        state.openApplicants.some(({ id }) => id === applicantId),
    isApplicantActive: state => applicantId =>
        state.activeApplicants.includes(applicantId)
};

export const mutations = {
    ADD_OPEN_APPLICANT(state, applicant) {
        state.openApplicants.push(applicant);
    },

    REMOVE_OPEN_APPLICANT(state, applicant) {
        state.openApplicants = state.openApplicants.filter(
            ({ id }) => id !== applicant.id
        );
    },

    SET_ACTIVE_APPLICANTS(state, activeApplicants) {
        state.activeApplicants = activeApplicants;
    }
};

export const actions = {
    ...crud.actions,

    downloadResume(context, { applicantId, resumeId, format }) {
        const params = {
            ...(format && { format })
        };

        return this.$axios.$get(
            `/applicants/${applicantId}/resumes/${resumeId}/download`,
            {
                params,
                responseType: 'blob'
            }
        );
    },

    attachToJob(context, data) {
        return this.$axios.$post(`/applicants/attach-to-job`, data);
    },

    addOpenApplicant({ commit }, applicant) {
        commit('ADD_OPEN_APPLICANT', applicant);
    },

    removeOpenApplicant({ commit }, applicant) {
        commit('REMOVE_OPEN_APPLICANT', applicant);
    },

    mergeApplicants(context, { id, data }) {
        return this.$axios.$post(`/applicants/${id}/merge`, data);
    },

    setActiveApplicants({ commit }, activeApplicants) {
        commit('SET_ACTIVE_APPLICANTS', activeApplicants);
    },

    openApplicant({ dispatch, getters, rootGetters }, applicant) {
        const { isApplicantOpen } = getters;

        if (isApplicantOpen(applicant.id)) {
            const tab = rootGetters['tabs/items'].find(
                tab => tab.applicantId === applicant.id
            );

            if (tab) {
                dispatch('tabs/setCurrentTab', tab.id, { root: true });
            }
        } else {
            this.$addTab({
                link: `/applicants/${applicant.id}`,
                label: applicant.name || this.$i18n.t('tabs.applicants.view'),
                addCallback: 'applicants/addOpenApplicant',
                addCallbackData: {
                    id: applicant.id,
                    name: applicant.name
                },
                removeCallback: 'applicants/removeOpenApplicant',
                removeCallbackData: {
                    id: applicant.id
                },
                applicantId: applicant.id
            });
        }
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
