<template>
    <li class="nav-item dropdown dropdown-user" @click="show = !show">
        <a
            class="nav-link dropdown-toggle dropdown-user-link"
            :class="{ show }"
            data-bs-toggle="dropdown"
        >
            <div class="user-nav d-sm-flex d-none">
                <span class="user-name">{{ $user.name }}</span>
                <span class="user-status">{{ role | startCase }}</span>
            </div>
            <span class="avatar">
                <img
                    alt="avatar"
                    class="round"
                    height="40"
                    :src="$user.avatar || ''"
                    width="40"
                />
                <span class="avatar-status-online" />
            </span>
        </a>
        <div class="dropdown-menu dropdown-menu-end" :class="{ show }">
            <a class="dropdown-item" @click="logout">
                <feather-icon type="power" class="me-50" />
                {{ $t('common.logout') }}
            </a>
        </div>
    </li>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            show: false
        };
    },

    computed: {
        role() {
            return this.$user?.role?.name || '';
        }
    },

    methods: {
        ...mapActions({
            logout: 'auth/logout'
        })
    }
};
</script>
