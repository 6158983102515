<template>
    <div class="container-fluid app-content content header-content app-header">
        <desktop-navigation v-if="$device.isDesktop" />
        <mobile-navigation v-else />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MobileNavigation from '@/components/layouts/MobileNavigation';
import DesktopNavigation from '@/components/layouts/DesktopNavigation';

export default {
    components: {
        MobileNavigation,
        DesktopNavigation
    },

    computed: {
        ...mapGetters({
            tabs: 'tabs/items',
            currentTab: 'tabs/currentTab'
        })
    },

    watch: {
        currentTab: {
            handler() {
                if (!this.currentTab) {
                    this.$openTab({
                        link: '/',
                        label: this.$t('tabs.dashboard')
                    });
                }
            },
            deep: true
        }
    },

    mounted() {
        const oftenUsed = JSON.parse(localStorage.oftenUsed || '[]');

        this.setOftenUsedTabs(oftenUsed);
    },

    methods: {
        ...mapActions({
            setOftenUsedTabs: 'tabs/setOftenUsed'
        })
    }
};
</script>
