export const defaultState = () => ({
    user: null
});

export const state = () => defaultState();

export const getters = {
    user: state => state.user,
    isAdmin: state => state.user?.role?.name === 'admin',
    isLoggedIn: state => !!state.user
};

export const mutations = {
    SET_USER(state, user) {
        state.user = user;
    }
};

export const actions = {
    async login({ commit }) {
        const user = await this.$axios.$get('/me');

        commit('SET_USER', user);

        this.$cookies.set('isLoggedIn', '1', new Date(2099, 1, 1));
    },

    async logout({ commit }) {
        await this.$axios.$post('/auth/logout');

        commit('SET_USER', null);

        this.$cookies.remove('isLoggedIn');
        this.$router.push('/login');
    },

    setUser({ commit }, user) {
        commit('SET_USER', user);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
