import pluralize from 'pluralize';
import camelCase from 'lodash/camelCase';

class BaseCrud {
    constructor(
        singular,
        plural = pluralize(singular),
        singularVariable = camelCase(singular),
        pluralVariable = camelCase(plural)
    ) {
        if (!singular) {
            throw new Error(
                'At least one parameter has to be provided to the BaseCrud constructor'
            );
        }

        const extractData = data => {
            return (
                data[this.state.singularVariable] ||
                data[this.state.pluralVariable] ||
                data
            );
        };

        this.state = {
            singular,
            plural,
            singularVariable,
            pluralVariable
        };
        this.getters = {};
        this.mutations = {};
        this.actions = {
            async index(
                { state },
                {
                    search = '',
                    currentPage = 1,
                    perPage = 10,
                    sortBy = ['createdAt:DESC'],
                    filters = null
                } = {}
            ) {
                const params = {
                    perPage,
                    sortBy,
                    q: search,
                    page: currentPage
                };

                if (filters) {
                    params.filters = JSON.stringify(filters);
                }

                const data = await this.$axios.$get(`/${state.plural}`, {
                    params
                });

                return extractData(data);
            },

            async store({ state }, data) {
                const responseData = await this.$axios.$post(
                    `/${state.plural}`,
                    data
                );

                return extractData(responseData);
            },

            async show({ state }, id) {
                const data = await this.$axios.$get(`/${state.plural}/${id}`);

                return extractData(data);
            },

            async update({ state }, { id, data }) {
                const responseData = await this.$axios.$put(
                    `/${state.plural}/${id}`,
                    data
                );

                return extractData(responseData);
            },

            destroy({ state }, id) {
                return this.$axios.$delete(`/${state.plural}/${id}`);
            }
        };
    }
}

export default BaseCrud;
