<template>
    <div :id="id" class="awesome-table" :class="{ card: tableOptions.isCard }">
        <div
            class="card-body"
            :style="{
                'margin-bottom': isLoading && !items.length ? '20px' : '0'
            }"
        >
            <div v-if="tableOptions.header" class="table-wrapper">
                <div class="table-header">
                    <div class="table-filters-wrapper">
                        <slot name="before-icons" />

                        <div class="icons">
                            <columns-selector
                                v-if="tableOptions.columnsSelector"
                                :labels="tableLabels"
                                @toggle-display="onToggleDisplay"
                            />

                            <copy-link
                                v-if="tableOptions.link"
                                :filters="tableOptions.filters ? filters : null"
                                :hidden-labels="hiddenLabels"
                                :pagination="
                                    tableOptions.pagination
                                        ? tablePagination
                                        : null
                                "
                                :additional-params="
                                    tableOptions.additionalParams
                                "
                            />

                            <applied-filters
                                v-if="tableOptions.filters"
                                :filters="appliedFilters"
                            />

                            <slot name="icons" />
                        </div>

                        <slot name="after-icons" />
                    </div>
                    <div
                        v-if="tableOptions.search"
                        class="input-group input-group-merge header-search"
                    >
                        <div class="input-group-append">
                            <span class="input-group-text">
                                {{ $t('common.search') }}
                            </span>
                        </div>
                        <input
                            :value="tablePagination.search"
                            class="form-control"
                            dir="auto"
                            :placeholder="$t('common.search')"
                            type="text"
                            @input="onSearchInput"
                        />

                        <span class="input-group-text">
                            <feather-icon type="search" />
                        </span>
                    </div>
                </div>
            </div>

            <div
                v-if="
                    tableOptions.select &&
                    tableOptions.showSelectedActions &&
                    selected.length
                "
                class="alert alert-info d-flex justify-content-between align-items-center selected-items-actions"
                role="alert"
            >
                <div>
                    <strong>
                        {{ $t('awesomeTable.selected') }}: {{ selected.length }}
                    </strong>
                </div>

                <div>
                    <slot name="selectButtons" />
                    <csv-export
                        v-if="tableOptions.csvExport"
                        :items="items"
                        :selected="selected"
                        :labels="tableLabels"
                        :table-options="tableOptions"
                    />

                    <button
                        class="btn btn-info waves-effect waves-light mr-1"
                        type="button"
                        @click="selected = []"
                    >
                        <feather-icon type="minus-circle" />
                        {{ $t('awesomeTable.clearSelection') }}
                    </button>

                    <button
                        v-if="tableOptions.copyField"
                        class="btn btn-info waves-effect waves-light mr-1"
                        type="button"
                        @click="copySelectedRowsToClipboard"
                    >
                        <feather-icon type="copy" />
                        {{ $t('awesomeTable.copySelectedRows') }}
                    </button>
                </div>
            </div>

            <div class="scroll-bar">
                <div class="inner" />
            </div>

            <div
                class="table-responsive"
                :class="{ 'fixed-header': tableOptions.fixedHeader }"
            >
                <table
                    class="table table-centered table-striped text-center"
                    :class="tableOptions.additionalClasses"
                >
                    <thead>
                        <tr>
                            <th v-if="showDrag" />

                            <th
                                v-for="(label, index) in displayableLabels"
                                :key="index + '_head'"
                                :class="{
                                    'sortable-label':
                                        tableOptions.sort &&
                                        label.sortable !== false,
                                    'fixed-column': label.fixed
                                }"
                                :style="labelStyle(label, index)"
                                @click.exact="onLabelClick(label)"
                                @click.alt.exact="onLabelClick(label, true)"
                            >
                                <div
                                    v-if="isSorted(label)"
                                    class="sortable-label-wrapper"
                                >
                                    <feather-icon
                                        v-if="isSorted(label)"
                                        :type="
                                            getSortingOrder(label) === 'DESC'
                                                ? 'arrow-down'
                                                : 'arrow-up'
                                        "
                                    />

                                    <slot
                                        :name="`labels.${label.value || label}`"
                                        :label="getLabel(label)"
                                    >
                                        {{ getLabel(label) }}
                                    </slot>
                                </div>
                                <slot
                                    v-else
                                    :name="`labels.${label.value || label}`"
                                    :label="getLabel(label)"
                                >
                                    {{ getLabel(label) }}
                                </slot>
                            </th>

                            <th v-if="tableOptions.select && items.length">
                                <div
                                    v-if="hasSelectedAnyElement"
                                    class="some-selected"
                                    @click="toggleAllOnPageSelected"
                                >
                                    <span> - </span>
                                </div>

                                <input
                                    v-else
                                    type="checkbox"
                                    :checked="isAnyItemOnPageSelected"
                                    @click="toggleAllOnPageSelected"
                                />
                            </th>
                        </tr>

                        <filters
                            v-if="tableOptions.filters"
                            :labels="displayableLabels"
                            :init-filters="filters"
                            :empty-cells-before="emptyCellsBeforeFiltersCount"
                            @filter-change="onFilterChange"
                        />

                        <tr
                            v-if="isLoading"
                            class="awesome-table-loader-wrapper"
                        >
                            <td>
                                <div class="awesome-table-loader">
                                    <div class="awesome-spinner">
                                        <div class="dot1" />

                                        <div class="dot2" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>

                    <draggable
                        v-if="items.length"
                        :key="dragKey"
                        tag="tbody"
                        :list="items"
                        group="people"
                        handle=".handle"
                        :style="{ opacity: isLoading ? '0.3' : '1' }"
                        @start="drag = true"
                        @end="onDragEnd"
                    >
                        <template v-for="(item, trIndex) in items">
                            <tr
                                :key="trIndex + '_body'"
                                :class="{
                                    [getRowClass(item, trIndex)]: true,
                                    'clickable-row': tableOptions.clickableRows,
                                    active: activeRows[trIndex]
                                }"
                                @click="onRowClick(item, trIndex)"
                            >
                                <td v-if="showDrag" @click.stop>
                                    <button
                                        type="button"
                                        class="handle btn btn-secondary waves-effect waves-light mr-2"
                                        style="cursor: move"
                                    >
                                        <feather-icon type="move" />
                                    </button>
                                </td>

                                <td
                                    v-for="(
                                        label, tdIndex
                                    ) in displayableLabels"
                                    :key="trIndex + '_body' + tdIndex"
                                    :ref="`td_${trIndex}_${tdIndex}`"
                                    :class="{
                                        'fixed-column': label.fixed
                                    }"
                                    :style="getCellStyle(label, tdIndex)"
                                >
                                    <slot
                                        :name="`items.${label.value || label}`"
                                        :value="getValue(item, label)"
                                        :item="{ ...item }"
                                        :index="trIndex"
                                    >
                                        <span
                                            v-if="
                                                isLabelBadgeOfBooleanType(label)
                                            "
                                        >
                                            <span
                                                v-if="isItemEmpty(item, label)"
                                                :class="
                                                    getBadgeClass(
                                                        label.badgeOptions,
                                                        'falseVariant'
                                                    )
                                                "
                                            >
                                                {{ $t('common.no') }}
                                            </span>

                                            <span
                                                v-else
                                                :class="
                                                    getBadgeClass(
                                                        label.badgeOptions,
                                                        'trueVariant'
                                                    )
                                                "
                                            >
                                                {{ $t('common.yes') }}
                                            </span>
                                        </span>

                                        <span v-else>
                                            {{ getValue(item, label) }}
                                        </span>
                                    </slot>
                                </td>

                                <td
                                    v-if="tableOptions.select"
                                    style="cursor: default"
                                    @click.stop
                                >
                                    <input
                                        type="checkbox"
                                        :checked="isSelected(item)"
                                        @click="toggleSelected(item)"
                                    />
                                </td>
                            </tr>
                            <slot name="items.dropdown" :item="item" />
                        </template>
                    </draggable>

                    <sum-section
                        :items="items"
                        :labels="displayableLabels"
                        :get-value="getValue"
                        :empty-cells-before="emptyCellsBeforeFiltersCount"
                    />

                    <tr v-if="!isLoading && !items.length">
                        <td :colspan="displayableLabels.length">
                            <b-alert class="text-center" show>
                                <b-row>
                                    <b-col
                                        v-for="index in tableAlertGridCols"
                                        :key="index"
                                    >
                                        {{ tableOptions.noDataText }}
                                    </b-col>
                                </b-row>
                            </b-alert>
                        </td>
                    </tr>
                </table>
            </div>

            <pagination
                v-if="tableOptions.pagination && tablePagination.total"
                :current-page="tablePagination.currentPage"
                :total="tablePagination.total"
                :per-page="tablePagination.perPage"
                @set-page="setPage"
                @set-per-page="onPerPageChange"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import cloneDeep from 'lodash/cloneDeep';

import Filters from './Filters';
import CopyLink from './CopyLink';
import CsvExport from './CsvExport';
import SumSection from './SumSection';
import Pagination from './Pagination';
import ColumnsSelector from './ColumnsSelector';
import getLabelHelper from './helpers/get-label';
import getValueHelper from './helpers/get-value';
import AppliedFilters from './filters/AppliedFilters';

export default {
    components: {
        Filters,
        CopyLink,
        CsvExport,
        Draggable,
        SumSection,
        Pagination,
        AppliedFilters,
        ColumnsSelector
    },

    props: {
        id: {
            type: String,
            required: false,
            default: 'awesome-table'
        },
        labels: {
            type: Array,
            required: true
        },
        items: {
            type: Array,
            required: true
        },
        options: {
            type: Object,
            required: false,
            default: () => ({})
        },
        pagination: {
            type: Object,
            required: false,
            default: () => ({})
        },
        getRowClass: {
            type: Function,
            required: false,
            default: () => ''
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        filters: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            defaultOptions: {
                singleSelectMode: false,
                addButton: true,
                addButtonText: this.$t('awesomeTable.addNew'),
                refreshButton: true,
                refreshButtonText: this.$t('awesomeTable.refresh'),
                emptyRow: '-',
                searchInput: true,
                topPagination: true,
                pagination: true,
                clickableRows: true,
                addQueryToUrl: true,
                sort: true,
                select: true,
                selectField: 'id',
                showSelectActions: true,
                drag: false,
                filters: true,
                link: true,
                additionalParams: {},
                additionalClasses: '',
                topScroll: false,
                noDataText: this.$t('awesomeTable.noDataFound'),
                noDataTextSuggestedFrequencyPerColumns: 3,
                noDataTextForcedFrequency: 0,
                csvExport: true,
                columnsSelector: true,
                header: true,
                isCard: true,
                fixedHeader: false,
                copyField: null,
                search: true
            },
            defaultPagination: {
                currentPage: 1,
                perPage: 10,
                search: '',
                total: 1,
                sortBy: ['createdAt:DESC']
            },
            selected: [],
            drag: false,
            dragKey: 0,
            appliedFilters: {},
            hiddenLabels: [],
            activeRows: {},
            rowClicks: 0,
            rowClickTimer: null
        };
    },

    computed: {
        ...mapGetters({
            isLtr: 'isLtr',
            isRtl: 'isRtl'
        }),

        tableLabels() {
            let labels = cloneDeep(this.labels);

            if (this.tableOptions.columnsSelector) {
                labels = labels.map(label => {
                    if (typeof label === 'string') {
                        label = {
                            value: label
                        };
                    }

                    return label;
                });
            }

            labels = labels.map(label => ({
                ...label,
                isHidden: this.hiddenLabels.includes(label.value)
            }));

            return labels;
        },

        query() {
            const query = {};

            if (this.pagination) {
                const { currentPage, perPage, search, sortBy } =
                    this.pagination;

                query.page = currentPage;
                query.perPage = perPage;
                query.sortBy = sortBy;

                if (search) {
                    query.q = search;
                }
            }

            if (this.filters && Object.keys(this.filters).length) {
                query.filters = JSON.stringify(this.filters);
            }

            if (this.hiddenLabels.length) {
                query.hiddenLabels = JSON.stringify(this.hiddenLabels);
            }

            if (this.additionalParams) {
                Object.assign(query, this.additionalParams);
            }

            return query;
        },

        tableOptions() {
            return { ...this.defaultOptions, ...this.options };
        },

        tablePagination() {
            return { ...this.defaultPagination, ...this.pagination };
        },

        isEveryItemOnPageSelected() {
            return this.items.every(item => {
                return this.selected.includes(
                    item[this.tableOptions.selectField]
                );
            });
        },

        isAnyItemOnPageSelected() {
            return this.items.some(item => {
                return this.selected.includes(
                    item[this.tableOptions.selectField]
                );
            });
        },

        showDrag() {
            if (!this.tableOptions.drag) {
                return false;
            }

            if (
                !this.isSortedBy('order') ||
                this.getSortingOrder('order') === 'DESC'
            ) {
                return false;
            }

            return this.items.length > 1;
        },

        emptyCellsBeforeFiltersCount() {
            let count = 0;

            if (this.showDrag) {
                count += 1;
            }

            if (this.tableOptions.select && this.items.length) {
                count += 1;
            }

            return count;
        },

        displayableLabels() {
            return this.tableLabels.filter(label => !label.isHidden);
        },

        tableAlertGridCols() {
            const {
                noDataTextSuggestedFrequencyPerColumns,
                noDataTextForcedFrequency
            } = this.tableOptions;

            if (noDataTextForcedFrequency > 0) {
                return noDataTextForcedFrequency;
            }

            const noDataTextFrequency =
                this.displayableLabels.length /
                noDataTextSuggestedFrequencyPerColumns;

            return Math.ceil(noDataTextFrequency);
        },

        hasPagination() {
            return !!(
                this.tableOptions.topPagination &&
                this.tableOptions.pagination &&
                this.tablePagination.total
            );
        },

        hasSelectedAnyElement() {
            return (
                this.isAnyItemOnPageSelected && !this.isEveryItemOnPageSelected
            );
        }
    },

    watch: {
        drag() {
            if (!this.drag) {
                this.dragKey += 1;
            }
        },
        items: {
            async handler() {
                if (this.options.topScroll) {
                    this.onTableWidthChange();
                }

                if (process.client) {
                    await this.$nextTick();

                    const table = document.querySelector(
                        '#awesome-table .table-responsive'
                    );

                    if (table) {
                        table.scrollLeft = 99999;
                    }
                }
            },
            immediate: true
        },

        query: {
            handler() {
                if (this.tableOptions.addQueryToUrl) {
                    this.$router.replace(
                        {
                            path: this.$route.path,
                            query: this.query
                        },
                        () => {}
                    );
                }
            },
            immediate: true,
            deep: true
        }
    },

    created() {
        if (this.tableOptions.link) {
            this.setHiddenLabels();
        }

        if (this.filters) {
            this.setAppliedFilters(this.filters);
        }

        if (this.tableOptions.selectField.includes('.')) {
            console.warn(
                '[WARNING] Select field should not be a value from the subobject!'
            );
        }
    },

    methods: {
        getCellStyle(label, index) {
            const style = {};

            if (label.fixed) {
                if (this.isLtr) {
                    style.left = `calc(${index}*${label.width})`;
                } else if (this.isRtl) {
                    style.right = `calc(${index}*${label.width})`;
                }
            }

            return style;
        },

        getSortingOrder(label) {
            const field = this.getSortingField(label);

            const [, order] = this.tablePagination.sortBy
                .map(item => item.split(':'))
                .find(item => {
                    const [key] = item;

                    return key === field;
                });

            return order;
        },

        onLabelClick(label, multiple = false) {
            if (!this.tableOptions.sort || label.sortable === false) {
                return;
            }

            let sortBy = cloneDeep(this.tablePagination.sortBy);
            const field = this.getSortingField(label);

            if (this.isSortedBy(label)) {
                sortBy = sortBy.map(item => {
                    // eslint-disable-next-line prefer-const
                    let [key, order] = item.split(':');

                    if (key === field) {
                        order = order === 'DESC' ? 'ASC' : 'DESC';
                    }

                    return `${key}:${order}`;
                });
            } else if (multiple) {
                sortBy.push(`${field}:DESC`);
            } else {
                sortBy = [`${field}:DESC`];
            }

            this.changePagination({
                sortBy,
                currentPage: 1
            });
        },

        getLabel(label) {
            return getLabelHelper(label);
        },

        labelStyle(label, index) {
            const styles = {
                cursor:
                    this.tableOptions.sort && label.sortable !== false
                        ? 'pointer'
                        : 'default',
                'min-width': label.width || ''
            };

            if (label.fixed) {
                if (this.isLtr) {
                    styles.left = `calc(${index}*${label.width})`;
                } else if (this.isRtl) {
                    styles.right = `calc(${index}*${label.width})`;
                }
            }

            return styles;
        },

        isSortedBy(label) {
            const field = this.getSortingField(label);

            return this.tablePagination.sortBy.some(item => {
                const [key] = item.split(':');

                return key === field;
            });
        },

        getSortingField(label) {
            const sortingField = label.fieldName || label.value || label || '';

            return sortingField;
        },

        getValue(item, label) {
            return getValueHelper(
                item,
                label,
                this.tableOptions,
                this.$options.filters
            );
        },

        onAddButtonClick() {
            this.$emit('add-button-click');
        },

        refresh() {
            this.$emit('refresh');
        },

        onRowClick(item, trIndex) {
            this.rowClicks++;

            if (this.rowClicks === 1) {
                this.activeRows = {
                    ...this.activeRows,
                    [trIndex]: !this.activeRows[trIndex]
                };

                this.$emit('row-click', item, trIndex);

                this.rowClickTimer = setTimeout(() => {
                    this.rowClicks = 0;
                }, 500);
            } else {
                clearTimeout(this.rowClickTimer);

                this.rowClicks = 0;

                this.$emit('row-double-click', item, trIndex);
            }
        },

        setPage(page) {
            this.changePagination({ currentPage: page });
        },

        onPerPageChange(perPage) {
            this.changePagination({ perPage, currentPage: 1 });
        },

        changePagination(pagination) {
            this.$emit('pagination-change', {
                ...this.tablePagination,
                ...pagination
            });
        },

        isSelected(item) {
            return this.selected.includes(item[this.tableOptions.selectField]);
        },

        toggleSelected(item) {
            if (this.tableOptions.singleSelectMode) {
                this.selected = [item[this.tableOptions.selectField]];

                this.$emit('selection-change', this.selected);

                return;
            }

            if (!this.isSelected(item)) {
                this.selected = [
                    ...this.selected,
                    item[this.tableOptions.selectField]
                ];
            } else {
                const index = this.selected.indexOf(
                    item[this.tableOptions.selectField]
                );

                this.selected.splice(index, 1);
            }

            this.$emit('selection-change', this.selected);
        },

        toggleAllOnPageSelected() {
            if (this.isAnyItemOnPageSelected) {
                this.items.forEach(item => {
                    const index = this.selected.indexOf(
                        item[this.tableOptions.selectField]
                    );

                    if (index !== -1) {
                        this.selected.splice(index, 1);
                    }
                });
            } else {
                this.items.forEach(item => {
                    const isIncluded = this.selected.includes(
                        item[this.tableOptions.selectField]
                    );

                    if (!isIncluded) {
                        this.selected = [
                            ...this.selected,
                            item[this.tableOptions.selectField]
                        ];
                    }
                });
            }

            this.$emit('selection-change', this.selected);
        },

        deselect(deselected = null) {
            if (!deselected) {
                this.selected = [];
            } else if (Array.isArray(deselected)) {
                this.selected = this.selected.filter(
                    i => !deselected.includes(i)
                );
            } else {
                const index = this.selected.indexOf(deselected);

                this.selected.splice(index, 1);
            }

            this.$emit('selection-change', this.selected);
        },

        onDragEnd() {
            this.drag = false;

            const minOrder = Math.min(...this.items.map(({ order }) => order));

            const elements = this.items.map((item, index) => ({
                id: item.id,
                order: minOrder + index
            }));

            this.$emit('elements-moved', elements);
        },

        onToggleSortButtonClick() {
            const orderBy = this.showDrag ? ['order:ASC'] : ['createdAt:DESC'];

            this.changePagination(orderBy);
        },

        onFilterChange(filters) {
            this.$emit('filter-change', filters);

            if (this.tablePagination.currentPage !== 1) {
                this.setPage(1);
            }

            this.setAppliedFilters(filters);
        },

        onSearchInput(event) {
            const { value: search } = event.target;

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {
                this.changePagination({ search, currentPage: 1 });
                this.$emit('search', search);
            }, 500);
        },

        setAppliedFilters(filters) {
            this.appliedFilters = cloneDeep(filters);

            for (const key in this.appliedFilters) {
                const label = this.tableLabels.find(label =>
                    [label.fieldName, label.value, label].includes(key)
                );

                if (!label) {
                    delete this.appliedFilters[key];

                    continue;
                }

                this.appliedFilters[key].text =
                    label.text || label.value || label;
                this.appliedFilters[key].fieldType = label.type || 'string';
            }
        },

        async onTableWidthChange() {
            await this.$nextTick();

            const awesomeTable = document.querySelector(
                '.awesome-table .table-responsive .table'
            );

            const innerScroll = document.querySelector(
                '.awesome-table .scroll-bar .inner'
            );

            if (!awesomeTable || !innerScroll) {
                return;
            }

            innerScroll.style.width = `${awesomeTable.scrollWidth}px`;

            const tableContainer = document.querySelector(
                '.awesome-table .table-responsive'
            );
            const outerScroll = document.querySelector(
                '.awesome-table .scroll-bar'
            );

            tableContainer.onscroll = () => {
                outerScroll.scrollLeft = tableContainer.scrollLeft;
            };

            outerScroll.onscroll = () => {
                tableContainer.scrollLeft = outerScroll.scrollLeft;
            };
        },

        onToggleDisplay(index) {
            const label = this.tableLabels[index];

            if (label.isHidden) {
                this.hiddenLabels = this.hiddenLabels.filter(
                    hiddenLabel => hiddenLabel !== label.value
                );
            } else {
                this.hiddenLabels.push(label.value);
            }

            this.tableLabels.splice(index, 1, {
                ...label,
                isHidden: !label.isHidden
            });
        },

        getBadgeClass(badgeOptions, variant) {
            let variantClass = variant === 'trueVariant' ? 'success' : 'danger';

            if (badgeOptions?.[variant]) {
                variantClass = badgeOptions[variant];
            }

            return `badge bg-soft-${variantClass} text-${variantClass}`;
        },

        setHiddenLabels() {
            const { hiddenLabels } = this.$route.query;

            this.hiddenLabels = hiddenLabels ? JSON.parse(hiddenLabels) : [];
        },

        isSorted(label) {
            return (
                this.tableOptions.sort &&
                label.sortable !== false &&
                this.isSortedBy(label)
            );
        },

        isLabelBadgeOfBooleanType(label) {
            return label.type === 'boolean' && label.isBadge;
        },

        isItemEmpty(item, label) {
            return this.getValue(item, label) === this.tableOptions.emptyRow;
        },

        copySelectedRowsToClipboard() {
            this.$emit('copy-selected-rows', this.selected);
        }
    }
};
</script>
