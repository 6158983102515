<template>
    <div class="table-footer">
        <div class="table-navigation-wrapper">
            <nav>
                <div id="rezume-table_paginate" class="rezume-table_paginate">
                    <ul class="pagination justify-content-left mt-2">
                        <li
                            class="page-item prev-item"
                            @click.prevent="setPage(currentPage - 1)"
                        >
                            <a class="page-link" />
                        </li>
                        <li
                            v-for="pageNumber in pages"
                            :key="pageNumber"
                            class="page-item"
                            :class="{ active: pageNumber === currentPage }"
                            @click="setPage(pageNumber)"
                        >
                            <a class="page-link">{{ pageNumber }}</a>
                        </li>
                        <li
                            class="page-item next-item"
                            @click.prevent="setPage(currentPage + 1)"
                        >
                            <a class="page-link" />
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div class="table-data-length-wrapper">
            <select
                v-model="rowsPerPage"
                :value="perPage"
                aria-controls="rezume-table"
                class="form-select"
                name="rezume_table_length"
                @change="onPerPageChange"
            >
                <option :value="10">10</option>
                <option :value="25">25</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
            </select>
            <span>{{ $t('awesomeTable.pagination.perPage') }}</span>
            <span>
                |
                {{ $t('awesomeTable.pagination.displaying') }}
                {{ recordsFrom }}-{{ recordsTo }}
                {{ $t('awesomeTable.pagination.of') }} {{ total }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: {
            type: Number,
            required: true
        },
        perPage: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            rowsPerPage: 10
        };
    },

    computed: {
        pages() {
            const maxPagesShown = 7;
            let startPage, endPage;

            if (this.lastPage <= maxPagesShown) {
                startPage = 1;
                endPage = this.lastPage;
            } else {
                const maxPagesShownBeforeCurrentPage = Math.floor(
                    maxPagesShown / 2
                );
                const maxPagesShownAfterCurrentPage =
                    Math.ceil(maxPagesShown / 2) - 1;

                if (this.currentPage <= maxPagesShownBeforeCurrentPage) {
                    startPage = 1;
                    endPage = maxPagesShown;
                } else if (
                    this.currentPage + maxPagesShownAfterCurrentPage >=
                    this.lastPage
                ) {
                    startPage = this.lastPage - maxPagesShown + 1;
                    endPage = this.lastPage;
                } else {
                    startPage =
                        this.currentPage - maxPagesShownBeforeCurrentPage;
                    endPage = this.currentPage + maxPagesShownAfterCurrentPage;
                }
            }

            const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
                i => startPage + i
            );

            return pages;
        },

        recordsFrom() {
            return this.currentPage * this.perPage - this.perPage + 1;
        },

        recordsTo() {
            let to = this.currentPage * this.perPage;

            if (to > this.total) {
                to = this.total;
            }

            return to;
        },

        lastPage() {
            return Math.ceil(this.total / this.perPage);
        }
    },

    watch: {
        perPage: {
            handler() {
                this.rowsPerPage = this.perPage;
            },
            immediate: true
        }
    },

    methods: {
        setPage(page) {
            if (page > 0 && page <= this.lastPage) {
                this.$emit('set-page', page);
            }
        },

        onPerPageChange() {
            this.$emit('set-per-page', this.rowsPerPage);
        }
    }
};
</script>
