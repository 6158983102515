<template>
    <div
        :class="{
            'lang-rtl': $i18n.locale === 'he'
        }"
    >
        <app-header />

        <div class="app-content content main-content-wrapper">
            <tabs />
            <nuxt />
        </div>

        <!-- // @TODO: ??? -->
        <div class="sidenav-overlay" />
        <div class="drag-target" />

        <portal-target name="modal" multiple />

        <info-modal />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Tabs from '@/components/layouts/Tabs';
import InfoModal from '@/components/modals/InfoModal';
import AppHeader from '@/components/layouts/AppHeader';

export default {
    components: {
        Tabs,
        AppHeader,
        InfoModal
    },

    head() {
        return {
            bodyAttrs: {
                class: 'horizontal-layout horizontal-menu navbar-floating footer-static rezume-layout',
                'data-menu': 'horizontal-menu',
                'data-open': 'hover'
            }
        };
    },

    computed: {
        ...mapGetters({
            openApplicants: 'applicants/openApplicants'
        })
    },

    watch: {
        '$i18n.locale'() {
            this.$cookies.set('locale', this.$i18n.locale, {
                expires: new Date(2099, 0, 1)
            });
        },

        openApplicants: {
            handler(applicants) {
                if (process.client) {
                    this.$socket.client.emit('open-applicants', {
                        userId: this.$user.id,
                        ids: applicants.map(({ id }) => id)
                    });
                }
            },
            deep: true,
            immediate: true
        }
    },

    beforeMount() {
        window.addEventListener('beforeunload', this.saveTabsNow);
    },

    created() {
        const locale = this.$cookies.get('locale');

        if (locale) {
            this.$i18n.setLocale(locale);
        }

        this.$nuxt.$on('award', award => this.addAward(award));
        this.$nuxt.$on('open-applicants', activeApplicants =>
            this.setActiveApplicants(activeApplicants)
        );
    },

    mounted() {
        this.$socket.$subscribe('event', event => {
            this.$nuxt.$emit(event.name, event.data);
        });
    },

    beforeDestroy() {
        this.$socket.$unsubscribe('event');
        window.removeEventListener('beforeunload', this.saveTabsNow);
    },

    methods: {
        ...mapActions({
            addAward: 'awards/addItem',
            saveTabsNow: 'tabs/saveTabsNow',
            setActiveApplicants: 'applicants/setActiveApplicants'
        })
    }
};
</script>
