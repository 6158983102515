<template>
    <li class="nav-item d-none d-xl-block header-nav">
        <a class="nav-link">
            <div class="input-group input-group-merge header-search">
                <input
                    v-model="search"
                    aria-describedby="basic-addon-search2"
                    class="form-control"
                    dir="auto"
                    :placeholder="$t('common.search')"
                    type="text"
                    @keyup.enter="submit"
                />

                <span
                    id="basic-addon-search2"
                    class="input-group-text"
                    @click="submit"
                >
                    <feather-icon type="search" />
                </span>
            </div>
        </a>
    </li>
</template>

<script>
export default {
    data() {
        return {
            search: null
        };
    },

    methods: {
        submit() {
            const search = this.search;

            this.search = null;

            this.$openTab({
                label: this.$t('tabs.applicants.list'),
                link: `/applicants?q=${search}`
            });
        }
    }
};
</script>
