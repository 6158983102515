export default async ({ $user, redirect, store: { dispatch, getters } }) => {
    if ($user?.role.name !== 'admin') {
        const currentTab = getters['tabs/currentTab'];

        if (currentTab) {
            await dispatch('tabs/removeTab', currentTab);
        }

        if (process.server) {
            redirect('/');
        }
    }
};
