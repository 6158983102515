<template>
    <li class="dropdown nav-item d-none d-xl-block">
        <a
            class="dropdown-toggle nav-link d-flex align-items-center"
            data-bs-toggle="dropdown"
        >
            <feather-icon type="chevron-down" />
            <span>{{ label }}</span>
        </a>

        <ul class="dropdown-menu">
            <dropdown-sub-menu-item
                v-for="(item, index) in items"
                :key="index"
                :label="item.label"
                :tab-label="item.tabLabel"
                :link="item.link"
                :icon="item.icon"
            />
        </ul>
    </li>
</template>

<script>
import DropdownSubMenuItem from '@/components/layouts/DropdownSubMenuItem';

export default {
    components: {
        DropdownSubMenuItem
    },

    props: {
        label: {
            type: String,
            required: true
        },
        items: {
            type: Array,
            required: true
        }
    }
};
</script>
