export default ({ store: { dispatch, getters }, redirect }, inject) => {
    const getTabById = getters['tabs/getTabById'];

    const updateOftenUsedTabs = tab => {
        const oftenUsed = JSON.parse(localStorage.oftenUsed || '[]');

        const tabIndex = oftenUsed.findIndex(
            oftenUsedTab => oftenUsedTab.link === tab.link
        );
        const tabAlreadyExists = Boolean(~tabIndex);

        if (tabAlreadyExists) {
            oftenUsed.splice(tabIndex, 1);
        } else if (oftenUsed.length >= 3) {
            const itemsToRemove = oftenUsed.length - 3;

            oftenUsed.splice(0, itemsToRemove || 1);
        }

        oftenUsed.push({
            link: tab.link,
            label: tab.label,
            tabLabel: tab.label
        });

        localStorage.oftenUsed = JSON.stringify(oftenUsed);

        dispatch('tabs/setOftenUsed', oftenUsed);
    };

    const addTab = tabData => dispatch('tabs/addTab', tabData);

    const openTab = async tabData => {
        const tabId = await dispatch('tabs/openTab', tabData);
        const tab = getTabById(tabId);

        const operator = tab.link.includes('?') ? '&' : '?';

        updateOftenUsedTabs(tab);

        redirect(`${tab.link}${operator}tabId=${tab.id}&fetch=1`);
    };

    const closeCurrentTab = async () => {
        const tab = getters['tabs/currentTab'];

        await dispatch('tabs/removeTab', tab);
    };

    const replaceCurrentTab = async tabData => {
        await closeCurrentTab();
        await openTab(tabData);
    };

    const updateCurrentTab = async (tabData = {}) => {
        const tabId = getters['tabs/currentTab']?.id;
        const tab = getTabById(tabId);
        console.log('>>> $updateCurrentTab >>> ', {
            tab,
            tabId
        });

        if (!tab) {
            return;
        }

        await dispatch('tabs/updateTab', {
            ...tab,
            ...tabData
        });
    };

    const updateTabById = async (tabId, tabData = {}) => {
        const tab = getTabById(tabId);
        console.log('>>> $updateTabById >>> ', {
            tab,
            tabId
        });

        if (!tab) {
            return;
        }

        await dispatch('tabs/updateTab', {
            ...tab,
            ...tabData
        });
    };

    inject('addTab', addTab);
    inject('openTab', openTab);
    inject('updateCurrentTab', updateCurrentTab);
    inject('updateTabById', updateTabById);
    inject('closeCurrentTab', closeCurrentTab);
    inject('replaceCurrentTab', replaceCurrentTab);
};
