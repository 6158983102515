<template>
    <div v-if="$showModal('info')" class="modal info-modal visible">
        <div class="modal__bg" />
        <div :class="`modal__dialog ${dirClass} ${additionalClasses}`">
            <div v-click-outside="$closeModal" class="modal__wrapper">
                <div v-if="header" class="modal__header">
                    {{ header }}
                </div>
                <div class="modal__content">
                    {{ content }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            modalData: 'modal/data'
        }),

        additionalClasses() {
            return this.modalData.class || '';
        },

        header() {
            return this.modalData?.header || '';
        },

        content() {
            return this.modalData?.content || '';
        },

        dirClass() {
            const dir = this.$i18n.locale === 'he' ? 'rtl' : 'ltr';

            return `dir-${dir}`;
        }
    }
};
</script>
