<template>
    <div class="filter-box">
        <div v-if="filterSelect" class="filter-wrapper">
            <feather-icon type="filter" class="filter-btn" />
            <div class="options">
                <v-select
                    v-model="type"
                    :options="types"
                    :clearable="false"
                    label="text"
                    @input="onTypeChange"
                />
            </div>
        </div>
        <input
            :value="value"
            :type="inputType"
            class="form-control"
            :placeholder="type.text"
            @input="onValueChange"
        />
        <feather-icon
            v-if="clearable && value"
            type="trash-2"
            @click="clearValue"
        />
    </div>
</template>

<script>
export default {
    props: {
        default: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            value: '',
            type: {},
            filterSelect: true,
            clearable: true,
            inputType: 'text',
            searchDelay: 500
        };
    },

    computed: {
        types() {
            return [
                {
                    text: this.$t('awesomeTable.filters.startsWith'),
                    value: 'startsWith'
                },
                {
                    text: this.$t('awesomeTable.filters.endsWith'),
                    value: 'endsWith'
                },
                {
                    text: this.$t('awesomeTable.filters.contains'),
                    value: 'substring',
                    default: true
                }
            ];
        }
    },

    watch: {
        '$i18n.locale': {
            handler() {
                if (this.type.value) {
                    const type = this.types.find(
                        type => type.value === this.type.value
                    );

                    if (type) {
                        this.type = type;
                    }
                }
            },
            immediate: true,
            deep: true
        }
    },

    created() {
        const defaultType = this.types.find(item => item.default);

        if (defaultType) {
            this.type = defaultType;
        }

        if (this.default) {
            this.value = this.default.value;

            const type = this.types.find(
                item => item.value === this.default.type
            );

            if (type) {
                this.type = { ...type };
            }
        }
    },

    methods: {
        onFilterChange(value) {
            const filter = {
                value,
                type: this.type.value
            };

            this.value = value;

            this.$emit('filter-change', filter);
        },

        clearValue() {
            this.onFilterChange('');
        },

        onValueChange(event) {
            const { value } = event.target;

            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }

            this.timer = setTimeout(() => {
                this.onFilterChange(value);
            }, this.searchDelay);
        },

        onTypeChange() {
            this.onFilterChange(this.value);
        }
    }
};
</script>
