<template>
    <li>
        <a
            class="dropdown-item dropdown-sub-item d-flex align-items-center"
            @click="$openTab({ label: tabLabel, link })"
        >
            <feather-icon :type="icon" />
            <span>{{ label }}</span>
        </a>
    </li>
</template>

<script>
export default {
    props: {
        link: {
            type: String,
            required: true
        },

        label: {
            type: String,
            required: true
        },

        tabLabel: {
            type: String,
            required: true
        },

        icon: {
            type: String,
            default: 'link'
        }
    }
};
</script>

<style>
.dropdown-sub-item .feather + span {
    margin-left: 10px;
}
</style>
