<template>
    <nav
        class="header-navbar navbar-expand-lg navbar align-items-left navbar-shadow navbar-brand-left"
    >
        <div class="navbar-container d-flex content">
            <div class="bookmark-wrapper d-flex align-items-center">
                <ul class="nav navbar-nav d-xl-none">
                    <li class="nav-item">
                        <a class="nav-link menu-toggle" href="#">
                            <feather-icon type="menu" class="ficon" />
                        </a>
                    </li>
                </ul>
                <li
                    class="nav-item d-none d-xl-block"
                    @click="
                        $openTab({
                            label: $t('tabs.dashboard'),
                            link: '/'
                        })
                    "
                >
                    <a class="navbar-brand">
                        <span class="brand-logo">
                            <rezume-logo />
                        </span>
                    </a>
                </li>
                <ul class="nav navbar-nav bookmark-icons">
                    <li class="nav-item d-none d-xl-block">
                        <a class="nav-link" title="Logout" @click="logout">
                            <feather-icon type="log-out" class="ficon" />
                        </a>
                    </li>

                    <admin-menu />

                    <li class="nav-item d-none d-xl-block">
                        <a class="nav-link" title="Refresh tab">
                            <feather-icon
                                type="repeat"
                                class="ficon"
                                @click="refreshTab"
                            />
                        </a>
                    </li>

                    <li class="nav-item d-none d-xl-block">
                        <a class="nav-link" title="Clear tabs">
                            <feather-icon
                                type="x-square"
                                class="ficon"
                                @click="clearTabs"
                            />
                        </a>
                    </li>

                    <li
                        class="dropdown nav-item d-none d-xl-block lang-selector"
                        data-menu="dropdown"
                    >
                        <a
                            class="dropdown-toggle nav-link d-flex align-items-center"
                            @click.prevent.stop="
                                $i18n.setLocale(
                                    $i18n.locale === 'en' ? 'he' : 'en'
                                )
                            "
                        >
                            {{ $i18n.locale === 'he' ? 'En' : 'He' }}
                        </a>
                    </li>
                </ul>
            </div>
            <ul class="nav nav-main navbar-nav align-items-center ms-auto">
                <search />

                <dropdown-menu-item
                    v-for="menuItem in filteredMenuItems"
                    :key="menuItem.id"
                    :label="menuItem.label"
                    :items="menuItem.items"
                />

                <notifications />
                <user-dropdown-menu />
            </ul>
        </div>
    </nav>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapGetters } from 'vuex';

import Search from '@/components/layouts/Search';
import AdminMenu from '@/components/layouts/AdminMenu';
import RezumeLogo from '@/components/layouts/RezumeLogo';
import Notifications from '@/components/layouts/Notifications';
import UserDropdownMenu from '@/components/layouts/UserDropdownMenu';
import DropdownMenuItem from '@/components/layouts/DropdownMenuItem';

export default {
    components: {
        Search,
        AdminMenu,
        RezumeLogo,
        Notifications,
        UserDropdownMenu,
        DropdownMenuItem
    },

    data() {
        return {
            showUsersMenu: false
        };
    },

    computed: {
        ...mapGetters({
            oftenUsed: 'tabs/oftenUsed'
        }),

        filteredMenuItems() {
            let menuItems = cloneDeep(this.menuItems);

            const userRole = this.$user?.role?.name;
            const roleFilter = ({ role }) => (role ? role === userRole : true);

            menuItems = menuItems.filter(roleFilter);

            for (const menu of menuItems) {
                menu.items = menu.items.filter(roleFilter);
            }

            return menuItems;
        },

        menuItems() {
            const menuItems = [
                {
                    label: this.$t('common.header.applicants'),
                    items: [
                        {
                            label: this.$t('common.header.list'),
                            tabLabel: this.$t('tabs.applicants.list'),
                            link: '/applicants',
                            icon: 'archive'
                        },
                        {
                            label: this.$t('common.header.new'),
                            tabLabel: this.$t('tabs.applicants.new'),
                            link: '/applicants/new',
                            icon: 'plus'
                        },
                        {
                            label: this.$t('common.header.hunter'),
                            tabLabel: this.$t('tabs.applicants.hunter'),
                            link: '/applicants/hunter',
                            icon: 'user'
                        },
                        {
                            label: this.$t('common.header.statuses'),
                            tabLabel: this.$t('tabs.applicants.statuses'),
                            link: '/applicants/statuses',
                            icon: 'list'
                        }
                    ]
                },
                {
                    role: 'admin',
                    label: this.$t('common.header.clients'),
                    items: [
                        {
                            label: this.$t('common.header.list'),
                            tabLabel: this.$t('tabs.clients.list'),
                            link: '/clients',
                            icon: 'archive'
                        },
                        {
                            label: this.$t('common.header.new'),
                            tabLabel: this.$t('tabs.clients.new'),
                            link: '/clients/new',
                            icon: 'plus'
                        }
                    ]
                },
                {
                    role: 'admin',
                    label: this.$t('common.header.jobs'),
                    items: [
                        {
                            label: this.$t('common.header.list'),
                            tabLabel: this.$t('tabs.jobs.list'),
                            link: '/jobs',
                            icon: 'archive'
                        },
                        {
                            label: this.$t('common.header.new'),
                            tabLabel: this.$t('tabs.jobs.new'),
                            link: '/jobs/new',
                            icon: 'plus'
                        }
                    ]
                },
                {
                    role: 'admin',
                    label: this.$t('common.header.suppliers'),
                    items: [
                        {
                            label: this.$t('common.header.list'),
                            tabLabel: this.$t('tabs.suppliers.list'),
                            link: '/suppliers',
                            icon: 'archive'
                        },
                        {
                            label: this.$t('common.header.new'),
                            tabLabel: this.$t('tabs.suppliers.new'),
                            link: '/suppliers/new',
                            icon: 'plus'
                        }
                    ]
                }
            ];

            if (this.oftenUsed.length) {
                menuItems.unshift({
                    label: this.$t('common.header.oftenUsed'),
                    items: this.oftenUsed
                });
            }

            return menuItems;
        }
    },

    methods: {
        ...mapActions({
            logout: 'auth/logout',
            refreshTab: 'tabs/refresh',
            clearTabs: 'tabs/clearTabs'
        })
    }
};
</script>
