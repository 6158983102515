<template>
    <div>
        <v-select
            v-model="value"
            class="select-filter"
            :options="options"
            :clearable="false"
            @input="onFilterChange"
        />
        <feather-icon v-if="value.length" type="trash-2" @click="clearValue" />
    </div>
</template>

<script>
export default {
    props: {
        default: {
            type: Object,
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            value: []
        };
    },

    created() {
        if (this.default) {
            this.value = this.default.value;
        }
    },

    methods: {
        onFilterChange() {
            const filter = {
                value: this.value?.value || this.value,
                type: 'eq'
            };

            this.$emit('filter-change', filter);
        },

        clearValue() {
            this.value = null;
            this.onFilterChange();
        }
    }
};
</script>
