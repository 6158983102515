import Vue from 'vue';
import { Bar, Line, Doughnut } from 'vue-chartjs/legacy';

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    ArcElement
} from 'chart.js';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    ArcElement
);

Vue.component('LineChart', {
    extends: Line
});

Vue.component('BarChart', {
    extends: Bar
});

Vue.component('DoughnutChart', {
    extends: Doughnut
});
