export default ({ store: { dispatch, getters } }, inject) => {
    inject('showModal', modal => getters['modal/show'](modal));

    inject('openModal', (name, data = null) => {
        dispatch('modal/open', {
            name,
            data
        });
    });

    inject('closeModal', () => dispatch('modal/close'));
};
