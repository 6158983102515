'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('job-applicant', 'job-applicants');

export const state = () => ({
    ...crud.state
});

export const getters = {};

export const mutations = {};

export const actions = {
    ...crud.actions,

    async index(
        context,
        {
            search = '',
            currentPage = 1,
            perPage = 10,
            sortBy = ['createdAt:DESC'],
            filters = null
        } = {}
    ) {
        const params = {
            perPage,
            sortBy,
            q: search,
            page: currentPage
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        const { items, total } = await this.$axios.$get(`/job-applicants`, {
            params
        });

        return { items, total };
    },

    updateMany(context, jobApplicantsData) {
        return this.$axios.$put(`/job-applicants/batch`, jobApplicantsData);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
