'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('applicant-file', 'applicant-files');

export const state = () => ({
    ...crud.state
});

export const getters = {};

export const mutations = {};

export const actions = {
    ...crud.actions
};

export default {
    state,
    getters,
    mutations,
    actions
};
