'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('client');

export const state = () => ({
    ...crud.state
});

export const getters = {};

export const mutations = {};

export const actions = {
    ...crud.actions,

    store(_, data) {
        return this.$axios.$post('/clients', data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
