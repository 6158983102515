<template>
    <div
        class="filter-box"
        :class="{
            'filter-box-big': ['between', 'not_between'].includes(type.value)
        }"
    >
        <div v-if="filterSelect" class="filter-wrapper">
            <feather-icon type="filter" class="filter-btn" />
            <div class="options">
                <v-select
                    v-model="type"
                    :options="types"
                    :clearable="false"
                    label="text"
                    @input="onTypeChange"
                />
            </div>
        </div>
        <div v-if="type.array" class="filter-number-between">
            <input
                :value="value[0]"
                :type="inputType"
                class="form-control"
                :placeholder="type.text"
                @input="onValueChange($event, 0)"
            />
            <input
                :value="value[1]"
                :type="inputType"
                class="form-control"
                :placeholder="type.text"
                @input="onValueChange($event, 1)"
            />
        </div>
        <input
            v-else
            :value="value"
            :type="inputType"
            class="form-control"
            :placeholder="type.text"
            @input="onValueChange"
        />
        <feather-icon
            v-if="clearable && value"
            type="trash-2"
            @click="clearValue"
        />
    </div>
</template>

<script>
import Base from './Base';

export default {
    extends: Base,

    data() {
        return {
            type: {},
            inputType: 'number'
        };
    },

    computed: {
        types() {
            return [
                {
                    text: this.$t('awesomeTable.filters.equals'),
                    value: 'eq',
                    default: true
                },
                {
                    text: this.$t('awesomeTable.filters.isNot'),
                    value: 'not'
                },
                {
                    text: this.$t('awesomeTable.filters.greaterThan'),
                    value: 'gt'
                },
                {
                    text: this.$t('awesomeTable.filters.greaterThanOrEqual'),
                    value: 'gte'
                },
                {
                    text: this.$t('awesomeTable.filters.lessThan'),
                    value: 'lt'
                },
                {
                    text: this.$t('awesomeTable.filters.lessThanOrEqual'),
                    value: 'lte'
                },
                {
                    text: this.$t('awesomeTable.filters.between'),
                    value: 'between',
                    array: true
                },
                {
                    text: this.$t('awesomeTable.filters.notBetween'),
                    value: 'not_between',
                    array: true
                }
            ];
        }
    },

    methods: {
        onFilterChange(value) {
            const filter = {
                value,
                type: this.type.value
            };

            this.value = value;

            if (this.type.array) {
                if (this.value.filter(item => item).length === 1) {
                    this.$emit('filter-change', {
                        type: this.type.value,
                        value: []
                    });

                    return;
                }

                if (!this.value.length) {
                    filter.value = [];
                }
            }

            this.$emit('filter-change', filter);
        },

        onValueChange(event, index) {
            let { value } = event.target;

            value = value !== '' ? parseInt(value) : '';

            if (this.type.array) {
                this.value[index] = value;
            } else {
                this.value = value;
            }

            this.onFilterChange(this.value);
        },

        clearValue() {
            this.onFilterChange(this.type.array ? [] : '');
        },

        onTypeChange() {
            this.value = this.type.array ? [] : '';

            this.onFilterChange(this.value);
        }
    }
};
</script>
