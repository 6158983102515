import Vue from 'vue';
import { io } from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';

import config from '@/config';

export default () => {
    const socket = io(config.socketUrl, {
        transports: ['websocket']
    });

    Vue.use(VueSocketIOExt, socket);
};
