<template>
    <div class="filter green" @click="copyToClipboard">
        <feather-icon type="copy" />
    </div>
</template>

<script>
import { appUrl } from '@/config';

export default {
    methods: {
        copyToClipboard() {
            const el = document.createElement('textarea');
            el.value = appUrl + this.$route.fullPath;

            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');

            document.body.removeChild(el);

            this.$openModal('info', {
                content: this.$t('common.copiedToClipboard')
            });
        }
    }
};
</script>
