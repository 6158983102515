import Vue from 'vue';
import vSelect from 'vue-select';

import FeatherIcon from '@/components/FeatherIcon';
import AwesomeTable from '@/components/awesome-table';

export default () => {
    Vue.component('vSelect', vSelect);
    Vue.component('FeatherIcon', FeatherIcon);
    Vue.component('AwesomeTable', AwesomeTable);
};
