<template>
    <tr :key="labels.length">
        <td
            v-for="(label, index) in labels"
            :key="index + '_filter'"
            class="filter"
        >
            <component
                :is="`filter-${label.type || 'string'}`"
                v-if="label.filterable !== false"
                :options="label.enumOptions"
                :default="initFilters[label.fieldName || label.value || label]"
                @filter-change="onFilterChange($event, label)"
            />
            <feather-icon v-else type="slash" class="filter-disabled" />
        </td>
        <td v-for="i in emptyCellsBefore" :key="i" />
    </tr>
</template>

<script>
import FilterEnum from './filters/Enum';
import FilterDate from './filters/Date';
import FilterSelect from './filters/Select';
import FilterNumber from './filters/Number';
import FilterString from './filters/String';
import FilterBoolean from './filters/Boolean';

export default {
    components: {
        FilterEnum,
        FilterDate,
        FilterSelect,
        FilterNumber,
        FilterString,
        FilterBoolean
    },

    props: {
        labels: {
            type: Array,
            required: false,
            default: () => []
        },

        initFilters: {
            type: Object,
            required: false,
            default: () => ({})
        },

        emptyCellsBefore: {
            type: Number,
            required: false,
            default: 0
        }
    },

    data() {
        return {
            filters: {}
        };
    },

    created() {
        this.filters = { ...this.initFilters };
    },

    methods: {
        onFilterChange(filter, label) {
            const property = label.fieldName || label.value || label;

            if (typeof label?.filterValue === 'function') {
                filter.value = label.filterValue(filter.value);
            }

            if (filter.value || filter.value === 0) {
                this.filters[property] = filter;
            } else {
                delete this.filters[property];
            }

            if (Array.isArray(filter.value) && !filter.value.length) {
                this.filters[property] = filter;
            }

            this.$emit('filter-change', this.filters);
        }
    }
};
</script>
