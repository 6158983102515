export default ({ store: { getters } }, inject) => {
    const user = new Proxy(
        {},
        {
            get(_, prop) {
                return getters['auth/user']?.[prop];
            }
        }
    );

    inject('user', user);
    inject('isAdmin', getters['auth/isAdmin']);
};
