'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('applicant-status', 'applicant-statuses');

export const state = () => ({
    ...crud.state
});

export const getters = {};

export const mutations = {};

export const actions = {
    ...crud.actions,

    updateOrder(context, statuses) {
        return this.$axios.$post('/applicant-statuses/order', {
            items: statuses.map((status, index) => ({
                id: status.id,
                order: index + 1
            }))
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
