<template>
    <div class="applied-filters">
        <div class="filter blue">
            <feather-icon type="filter" />
        </div>
        <div class="applied-filters-list card">
            <div v-if="filtersLength" class="card-body">
                <span
                    v-for="(value, key) in appliedFilters"
                    :key="key"
                    class="applied-filter"
                >
                    <span>{{ value.text | startCase }}</span>
                    {{ getFilterText(value) }}
                </span>
            </div>
            <div v-else class="card-body">
                {{ $t('awesomeTable.noFiltersApplied') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        filters: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            prefixes: {
                substring: 'contains',
                endsWith: 'ends with',
                startsWith: 'starts with',
                eq: 'is',
                not: 'is not',
                gt: 'greater than',
                gte: 'greater than or equal',
                lt: 'less than',
                lte: 'less than or equal',
                between: 'between',
                notBetween: 'not between',
                in: 'is one of'
            }
        };
    },

    computed: {
        filtersLength() {
            return Object.keys(this.appliedFilters).length;
        },

        appliedFilters() {
            const appliedFilters = {};

            for (const [name, filter] of Object.entries(this.filters)) {
                if (filter.value !== null) {
                    appliedFilters[name] = filter;
                }
            }

            return appliedFilters;
        }
    },

    methods: {
        getFilterText(filter) {
            const { type, fieldType } = filter;
            let { value } = filter;

            if (fieldType === 'boolean') {
                value = value ? 'active' : 'inactive';
            } else if (Array.isArray(value)) {
                value = value.join(', ');
            } else {
                value = `"${value}"`;
            }

            return `${this.prefixes[type]} ${value}`;
        }
    }
};
</script>
