var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$user.role.name === 'admin')?_c('li',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"nav-item d-none d-xl-block",on:{"click":function($event){_vm.show = true}}},[_c('a',{staticClass:"nav-link dropdown-toggle d-flex align-items-center",attrs:{"title":"Settings"}},[_c('feather-icon',{staticClass:"ficon",attrs:{"type":"settings"}})],1),_vm._v(" "),_c('ul',{staticClass:"dropdown-menu",class:{ show: _vm.show }},[_c('li',[_c('a',{staticClass:"dropdown-item d-flex align-items-center",on:{"click":function($event){_vm.showUsersMenu = true}}},[_c('span',[_c('feather-icon',{attrs:{"type":"chevron-down"}}),_vm._v("\n                    "+_vm._s(_vm.$t('common.header.users'))+"\n                ")],1)]),_vm._v(" "),_c('ul',{staticClass:"dropdown-menu",class:{ show: _vm.showUsersMenu }},[_c('li',[_c('a',{staticClass:"dropdown-item d-flex align-items-center",on:{"click":function($event){_vm.$openTab({
                                label: _vm.$t('tabs.users.list'),
                                link: '/users'
                            })}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.header.list')))])])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"dropdown-item d-flex align-items-center",on:{"click":function($event){_vm.$openTab({
                                label: _vm.$t('tabs.users.new'),
                                link: '/users/new'
                            })}}},[_c('span',[_vm._v(_vm._s(_vm.$t('common.header.new')))])])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"dropdown-item d-flex align-items-center",on:{"click":function($event){_vm.$openTab({
                                label: _vm.$t('tabs.users.award'),
                                link: '/users/award'
                            })}}},[_c('span',[_vm._v("\n                            "+_vm._s(_vm.$t('common.header.manageNotifications'))+"\n                        ")])])])])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"dropdown-item d-flex align-items-center",on:{"click":function($event){_vm.$openTab({
                        label: _vm.$t('tabs.applicants.statuses'),
                        link: '/applicants/statuses'
                    })}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.header.statusManagement'))+" ")])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }