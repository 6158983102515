<template>
    <li
        :class="{
            active: isActive
        }"
        @click="openTab"
    >
        <feather-icon
            type="x"
            :color="isActive ? '#ff9f43' : '#000'"
            @click="doRemoveTab"
        />
        <span>
            <font style="vertical-align: inherit">
                <font style="vertical-align: inherit">
                    {{ label }}
                </font>
            </font>
        </span>
    </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        tab: {
            type: Object,
            required: false,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            currentTab: 'tabs/currentTab'
        }),

        isActive() {
            return this.currentTab?.id === this.tab.id;
        },

        tabLink() {
            const operator = this.tab.link.includes('?') ? '&' : '?';

            const link = `${this.tab.link}${operator}tabId=${this.tab.id}`;

            return this.tab.fetch ? link : link + '&fetch=1';
        },

        label() {
            return (
                this.tab?.label || this.tab?.data?.tabLabel || this.tab?.link
            );
        }
    },

    methods: {
        ...mapActions({
            removeTab: 'tabs/removeTab'
        }),

        openTab() {
            if (this.tab.id === this.currentTab.id) {
                return;
            }

            this.$router.push(this.tabLink);
        },

        doRemoveTab() {
            this.removeTab(this.tab);
        }
    }
};
</script>
