<template>
    <div class="filter-box filter-box-date">
        <div v-if="filterSelect" class="filter-wrapper">
            <feather-icon type="filter" class="filter-btn" />
            <div class="options">
                <v-select
                    v-model="type"
                    :options="types"
                    :clearable="false"
                    label="text"
                    @input="onTypeChange"
                />
            </div>
        </div>
        <div class="filter-box-date">
            <date-picker
                :key="type.value"
                :value="value"
                class="form-control"
                :placeholder="type.text"
                :config="datePickerConfig"
                @on-change="onValueChange"
            />

            <feather-icon
                v-if="clearable && value"
                type="trash-2"
                @click="clearValue"
            />
        </div>
    </div>
</template>

<script>
import Base from './Base';

export default {
    extends: Base,

    data() {
        return {
            type: {}
        };
    },

    computed: {
        datePickerConfig() {
            const locale = this.$getDatePickerLocale();

            const config = {
                wrap: true,
                dateFormat: 'Y-m-d',
                locale
            };

            if (this.type.array) {
                config.mode = 'range';
            }

            return config;
        },

        types() {
            return [
                {
                    text: this.$t('awesomeTable.filters.equals'),
                    value: 'eq',
                    default: true
                },
                {
                    text: this.$t('awesomeTable.filters.isNot'),
                    value: 'not'
                },
                {
                    text: this.$t('awesomeTable.filters.greaterThanOrEqual'),
                    value: 'gte'
                },
                {
                    text: this.$t('awesomeTable.filters.lessThanOrEqual'),
                    value: 'lte'
                },
                {
                    text: this.$t('awesomeTable.filters.between'),
                    value: 'between',
                    array: true
                },
                {
                    text: this.$t('awesomeTable.filters.notBetween'),
                    value: 'notBetween',
                    array: true
                }
            ];
        }
    },

    methods: {
        onFilterChange(value) {
            const filter = {
                value,
                type: this.type.value
            };

            this.value = value;

            if (this.type.array) {
                if (this.value.filter(item => item).length === 1) {
                    this.$emit('filter-change', {
                        type: this.type.value,
                        value: []
                    });

                    return;
                }

                if (!this.value.length) {
                    filter.value = [];
                }
            }

            this.$emit('filter-change', filter);
        },

        onValueChange(dates) {
            if (!dates.length) {
                return;
            }

            const mappedDates = dates.map(date =>
                this.$dayjs(date).format('YYYY-MM-DD')
            );

            let value = mappedDates.length === 1 ? mappedDates[0] : mappedDates;

            if (this.type.array) {
                value = Array.isArray(value) ? value : [value];
            }

            if (JSON.stringify(value) !== JSON.stringify(this.value)) {
                this.onFilterChange(value);
            }
        },

        clearValue() {
            this.onFilterChange(this.type.array ? [] : '');
        },

        onTypeChange() {
            this.value = this.type.array ? [] : '';

            this.onFilterChange(this.value);
        }
    }
};
</script>
