'use strict';

import BaseCrud from '@/helpers/BaseCrud';

const crud = new BaseCrud('applicant-hunter', 'applicant-hunters');

const mapFilters = filters => {
    const idMappingKeys = ['supplierId', 'clientId', 'jobId', 'statusId'];

    for (const key of idMappingKeys) {
        filters[key] = {
            ...filters[key],
            value: filters[key].value.map(item =>
                typeof item === 'string' ? item : item.id
            )
        };
    }

    const doubleQuoteMappingKeys = [
        'entireResume',
        'experience',
        'education',
        'notes',
        'city'
    ];

    for (const key of doubleQuoteMappingKeys) {
        const [item = ''] = filters[key].value;

        filters[key] = {
            ...filters[key],
            value: (item.match(/"([^"]+)"|״([^״]+)״/g) || []).map(phrase =>
                phrase.slice(1, -1)
            )
        };
    }

    return filters;
};

export const state = () => ({
    ...crud.state,
    id: null,
    name: null,
    owner: null,
    pagination: {
        currentPage: 1,
        perPage: 25,
        total: 0,
        search: '',
        sortBy: ['createdAt:DESC']
    },
    selectedApplicant: {},
    settings: {
        showUnknownAge: true,
        sortByUpdateDate: true,
        showUnknownLocation: true,
        searchEntireResume: false,
        currentStatusOnly: true
    },
    filters: {
        entireResume: {
            type: 'substringArray',
            value: ['']
        },
        experience: {
            type: 'substringArray',
            value: ['']
        },
        education: {
            type: 'substringArray',
            value: ['']
        },
        notes: {
            type: 'substringArray',
            value: ['']
        },
        city: {
            type: 'in',
            value: ['']
        },
        age: {
            type: 'between',
            value: []
        },
        resumeReceivedDate: {
            type: 'between',
            value: []
        },
        updatedAt: {
            type: 'between',
            value: []
        },
        supplierId: {
            type: 'in',
            value: []
        },
        supplierType: {
            type: 'eq',
            value: null
        },
        clientId: {
            type: 'in',
            value: []
        },
        jobId: {
            type: 'in',
            value: []
        },
        statusId: {
            type: 'in',
            value: []
        },
        updatedById: {
            type: 'in',
            value: []
        },
        activity: {
            type: 'between',
            value: []
        }
    },
    tableFilters: {
        age: {
            type: 'eq',
            value: null
        },
        name: {
            type: 'substring',
            value: null
        }
    },
    aggregations: {
        supplierId: {
            type: 'in',
            value: []
        },
        stage: {
            type: 'in',
            value: []
        },
        city: {
            type: 'in',
            value: []
        },
        updatedAt: {
            type: 'between',
            value: []
        }
    },
    cities: []
});

export const getters = {
    id: state => state.id,
    name: state => state.name,
    owner: state => state.owner,
    filters: state => state.filters,
    selectedApplicant: state => state.selectedApplicant,
    aggregations: state => state.aggregations,
    settings: state => state.settings,
    pagination: state => state.pagination,
    tableFilters: state => state.tableFilters,
    cities: state => state.cities,
    state: state => state
};

export const mutations = {
    RESTORE_STATE(currentState, newState) {
        const updatedState = JSON.parse(
            JSON.stringify({ ...state(), ...newState })
        );

        for (const [name, value] of Object.entries(updatedState)) {
            if (value !== null) {
                currentState[name] = value;
            }
        }
    },

    SET_CITIES(state, cities) {
        state.cities = cities;
    },

    SET_SELECTED_APPLICANT(state, selectedApplicant) {
        state.selectedApplicant = { ...selectedApplicant };
    },

    SET_ID(state, id) {
        state.id = id;
    },

    SET_NAME(state, name) {
        state.name = name;
    },

    SET_OWNER(state, owner) {
        state.owner = owner;
    },

    SET_FILTERS(currentState, filters) {
        currentState.filters = JSON.parse(
            JSON.stringify(filters || state().filters)
        );
    },

    SET_TABLE_FILTERS(currentState, tableFilters) {
        currentState.tableFilters = {
            ...state().tableFilters,
            ...tableFilters
        };
    },

    SET_FILTER(state, filter) {
        if (filter.value.value === undefined) {
            filter.value.value = null;
        }

        state.filters = JSON.parse(
            JSON.stringify({
                ...state.filters,
                [filter.name]: filter.value
            })
        );
    },

    SET_FILTER_VALUE(state, filter) {
        if (filter.value === undefined) {
            filter.value = null;
        }

        state.filters = JSON.parse(
            JSON.stringify({
                ...state.filters,
                [filter.name]: {
                    ...state.filters[filter.name],
                    value: filter.value
                }
            })
        );
    },

    RESET_FILTERS(currentState) {
        currentState.filters = state().filters;
    },

    RESET_TABLE_FILTERS(currentState) {
        currentState.tableFilters = state().tableFilters;
    },

    RESET_AGGREGATIONS(currentState) {
        currentState.aggregations = state().aggregations;
    },

    SET_AGGREGATION(state, { key, value }) {
        state.aggregations = JSON.parse(
            JSON.stringify({
                ...state.aggregations,
                [key]: {
                    ...state.aggregations[key],
                    value
                }
            })
        );
    },

    SET_AGGREGATIONS(currentState, aggregations = {}) {
        currentState.aggregations = JSON.parse(
            JSON.stringify({
                ...state().aggregations,
                ...aggregations
            })
        );
    },

    SET_SETTINGS(state, settings) {
        state.settings = { ...settings };
    },

    SET_SETTING(state, setting) {
        state.settings = JSON.parse(
            JSON.stringify({
                ...state.settings,
                [setting.name]: setting.value
            })
        );
    },

    SET_PAGINATION(state, pagination) {
        state.pagination = { ...pagination };
    }
};

export const actions = {
    ...crud.actions,

    setId({ commit }, id) {
        commit('SET_ID', id);
    },

    setName({ commit }, name) {
        commit('SET_NAME', name);
    },

    setOwner({ commit }, owner) {
        commit('SET_OWNER', owner);
    },

    setFilters({ commit }, filters) {
        commit('SET_FILTERS', filters);
    },

    setTableFilters({ commit }, filters) {
        commit('SET_TABLE_FILTERS', filters);
    },

    setFilter({ commit }, filter) {
        commit('SET_FILTER', filter);
    },

    resetFilters({ commit }) {
        commit('RESET_FILTERS');
    },

    resetTableFilters({ commit }) {
        commit('RESET_TABLE_FILTERS');
    },

    resetAggregations({ commit }) {
        commit('RESET_AGGREGATIONS');
    },

    searchApplicant(
        {
            getters: {
                aggregations,
                filters: searchFormFilters,
                tableFilters,
                pagination,
                settings
            }
        },
        { download = false } = {}
    ) {
        const applySupplierAggregation =
            aggregations?.supplierId?.value?.length;
        const applyUpdatedAtAggregation =
            aggregations?.updatedAt?.value?.length;

        const filters = {
            ...aggregations,
            ...searchFormFilters,
            ...tableFilters,
            ...(applySupplierAggregation && {
                supplierId: aggregations.supplierId
            }),
            ...(applyUpdatedAtAggregation && {
                updatedAt: aggregations.updatedAt
            }),
            city: {
                ...aggregations.city,
                value: [
                    aggregations.city.value.map(city => `"${city}"`).join(' ')
                ]
            }
        };

        return this.$axios.$post(
            `/applicant-hunters/search`,
            {
                filters: mapFilters({ ...filters }),
                settings,
                pagination,
                download
            },
            {
                ...(download ? { responseType: 'blob' } : {})
            }
        );
    },

    setAggregation({ commit }, aggregation) {
        commit('SET_AGGREGATION', aggregation);
    },

    setAggregations({ commit }, aggregations) {
        commit('SET_AGGREGATIONS', aggregations);
    },

    setSetting({ commit }, setting) {
        commit('SET_SETTING', setting);
    },

    setSettings({ commit }, settings) {
        commit('SET_SETTINGS', settings);
    },

    setPagination({ commit }, pagination) {
        commit('SET_PAGINATION', pagination);
    },

    setFilterValue({ commit }, filter) {
        commit('SET_FILTER_VALUE', filter);
    },

    restoreState({ commit }, newState = {}) {
        commit('RESTORE_STATE', newState);
    },

    setSelectedApplicant({ commit }, selectedApplicant) {
        commit('SET_SELECTED_APPLICANT', selectedApplicant);
    },

    async getCities({ commit }) {
        const cities = await this.$axios.$get('/applicant-hunters/cities');

        commit('SET_CITIES', cities);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
