export default (item, label, tableOptions, filters) => {
    const labelValue = label.value || label;
    const properties = labelValue.split('.');

    let value = { ...item };

    for (let property of properties) {
        const isTableElement = property.includes('[');

        if (isTableElement) {
            let elements = property.split('[');

            if (elements.length < 2) {
                return tableOptions.emptyRow;
            }

            value = value[elements[0]];
            elements = elements[1].split(']');

            try {
                property = parseInt(elements[0]);
            } catch (error) {
                return tableOptions.emptyRow;
            }
        }

        if (!value[property] && value[property] !== 0) {
            return tableOptions.emptyRow;
        }

        value = value[property];
    }

    const { filter } = label;

    if (filter) {
        if (typeof filter === 'function') {
            return filter(value, item);
        }

        return filters[filter](value);
    }

    return value;
};
